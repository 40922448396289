import React from 'react'
import { useSelector } from 'react-redux';
import DashboardsIcon from '../../../../components/icons/DashboardsIcon'
import UserRightChevronIcon from '../../../../components/icons/RightChevronIcon';
import lodash from 'lodash'

function AssetSummaryDetailBreadCrumbs(props) {
    const assetsSelectorOptions = useSelector(state => state.assetSummary.assetsSelectorOptions)

    return (
        <div className='breadcumbs'>
            <div className='dashboard-icon-view'>
                <DashboardsIcon color="#667085" />
            </div>
            <div className='tabs'>
                <div className='text-view'>
                    <span className="text">Dashboards</span>
                </div>
                <UserRightChevronIcon />
                <div className='text-view'>
                    <span className="text">Asset Summary</span>
                </div>
                <UserRightChevronIcon />
                <div className='bank-account-text-view'>
                    <span className="text">{ assetsSelectorOptions && assetsSelectorOptions.length > 0 && lodash.find(assetsSelectorOptions, function (option) { return option.value === props.asset_id; }).label }</span>
                </div>
            </div>
        </div>
    )
}

export default AssetSummaryDetailBreadCrumbs