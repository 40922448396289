import React from 'react';
import EventsDataBaseIcon from '../../../components/icons/EventsDataBaseIcon';
import UserRightChevronIcon from '../../../components/icons/RightChevronIcon';

export default function EventDataBaseBreadCrumbs () {
  return (
    <div className="event-database-breadcrumbs">
        <EventsDataBaseIcon color="#667085" />
        <span className="database">Databases</span>
        <UserRightChevronIcon />
        <span className="event-database">Events</span>
    </div>
  )
}