import React from 'react';
import DashboardsIcon from '../../../../components/icons/DashboardsIcon';
import UserRightChevronIcon from '../../../../components/icons/RightChevronIcon';

export default function PortfolioOverviewBreadCrumbs () {
  return (
    <div className="portfolio-overview-page-breadcrumbs">
        <DashboardsIcon color="#667085" />
        <span className="management">Dashboards</span>
        <UserRightChevronIcon />
        <span className="portfolio-overview-page">Portfolio Overview</span>
    </div>
  )
}