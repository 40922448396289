import { Stack, Box } from "@mui/material";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import SideBarLogo from "../icons/SideBarLogo";

export default function SideBar (props) {

  return (
    <>
      <Stack
        display='flex'
        padding='0rem 1.5rem'
        flexDirection='column'
        alignItems='flex-start'
        aligndelf='stretch'
      >
        <SideBarLogo />
      </Stack>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        alignSelf='stretch'
        gap='0.25rem'
      >
        { 
          props.routes && props.routes.map((route, index) => (
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SidebarItem single={route.single} item={route} key={index} />
              )
            ) : null
          ))
        }
      </Box>
      </>
  );
};