import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import lodash from 'lodash';
import Loader from '../../../../components/commonComponents/Loader';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SimpleSelectInput from '../../../../components/commonComponents/SimpleSelectInput';

import { getOrganizationIdOFLoggedUser } from '../../../../util/commonFunctions';
import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';
import { getYTDIRR } from '../../../../redux/slices/portfolio/portfolioSlice';
import { setIrrByCategoryViewGraph, getIrreByCategory, resetIrrByCategoryViewGraph } from '../../../../redux/slices/portfolio/portfolioSlice';
import { cloneDeep } from 'lodash';

function IrrByCategoryGraph() {
  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user)
  const dates = useSelector(state => state.portfolio.dates)
  const categories = useSelector(state => state.portfolio.categories)
  const entities = useSelector(state => state.portfolio.entities)
  const filterDate = useSelector(state => state.portfolio.filterDate)
  const filterCategory = useSelector(state => state.portfolio.filterCategory)
  const filterEntity = useSelector(state => state.portfolio.filterEntity)
  const exposureByCategoryGraphSeries = useSelector(state => state.portfolio.exposureByCategoryGraphSeries)
  const exposureByCategoryGraphCategories = useSelector(state => state.portfolio.exposureByCategoryGraphCategories)
  const exposureByCategoryGraphLabels = useSelector(state => state.portfolio.exposureByCategoryGraphLabels)
  const exposureByCategoryGraphColors = useSelector(state => state.portfolio.exposureByCategoryGraphColors)
  const exposureByCategoryGraph = useSelector(state => state.portfolio.exposureByCategoryGraph)
  const irrByCategoryGraph = useSelector(state => state.portfolio.irrByCategoryGraph)
  const series = useSelector(state => state.portfolio.irrByCategoryGraphSeries)
  const graphCategories = useSelector(state => state.portfolio.irrByCategoryGraphCategories)

  const [irrCategorySelectorValue, setIrrCategorySelectorFilter] = useState(1)
  const [irrByCategoryGraphLoading, setIrrByCategoryGraphLoading] = useState(true)

  const irrCategoriesOptions = [
    { label: 'YTD', value: 1},
    { label: 'Overall', value: 2},
  ];
  
  useGetIrrByCategory(dispatch, user, filterDate, filterCategory, filterEntity, dates, categories, entities,
    exposureByCategoryGraphSeries, exposureByCategoryGraphCategories, exposureByCategoryGraphLabels,
    exposureByCategoryGraphColors, exposureByCategoryGraph, irrByCategoryGraph, irrCategorySelectorValue, lodash, setIrrByCategoryGraphLoading);
  
  const graphConfig = useMemo(() => {
    let config = {};
    if(irrByCategoryGraph && irrByCategoryGraph.hasOwnProperty('graphType') && irrByCategoryGraph.hasOwnProperty('menuText') && series) {
      if(irrByCategoryGraph.graphType === 'bar' && graphCategories && irrByCategoryGraph.selectedFilter === 'view-irr-category') {
        let maxDataValue = Math.max(...series[0]['data']);
        if (maxDataValue % 5 !== 0) {
          maxDataValue = Math.ceil(maxDataValue / 5) * 5;
        }
        config = cloneDeep({
          series: series,
          options: {
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: true
              }
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }],
            plotOptions: {
              bar: {
                horizontal: false,
                // borderRadius: 10,
                columnWidth: '25%',
                dataLabels: {
                  total: {
                    enabled: true,
                    formatter: function (val) {
                      // Format the label text with commas for thousands and 2 decimal places
                      return val.toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) + '%';
                      
                    },
                    offsetY: -15,
                    style: {
                      fontSize: '13px',
                      fontWeight: 900,
                    },
                  },
                },
              },
            },
            yaxis: {
              title: {
                  text: 'Rating',
                  offsetX: 5,
              },
              labels: {
              formatter: function (value, index) {
                if (value === 0) {
                  return '0';
                } else {
                  return value.toLocaleString('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }) + '%';
                }
              },
            },
              tickAmount: 5,
              min: 0,
              max: maxDataValue
            },
            xaxis: {
              title: {
                text: 'Category',
                position: 'bottom',
              },
              categories: graphCategories,
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
            },
            fill: {
              opacity: 1
            },
            dataLabels: {
              enabled: false,
            },
            tooltip: {
              enabled: true, // Enable tooltips
              y: {
                formatter: function (val) {
                  return (val/100).toLocaleString('en-US', {
                    style: 'percent',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                }
              }
            }      
            }
          });
        }
        else if(irrByCategoryGraph.graphType === 'bar' && graphCategories && irrByCategoryGraph.selectedFilter === 'view-gain-loss') {
          let maxDataValue = Math.max(...series[0]['data']);
          if (maxDataValue % 5 !== 0) {
            maxDataValue = Math.ceil(maxDataValue / 5) * 5;
          }
          config = cloneDeep({
              series: series,
              options: {
                chart: {
                  type: 'bar',
                  height: 350,
                  stacked: true,
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: true
                  }
                },
                responsive: [{
                  breakpoint: 480,
                  options: {
                    legend: {
                      position: 'bottom',
                      offsetX: -10,
                      offsetY: 0
                    }
                  }
                }],
                plotOptions: {
                  bar: {
                    horizontal: false,
                    borderRadius: 10,
                    columnWidth: '25%',
                    dataLabels: {
                      total: {
                        enabled: true,
                        formatter: function (val) {
                          // Format the label text with commas for thousands and 2 decimal places
                          if (!val) {
                            val = 0
                          }
                          return '$' + val.toLocaleString('en-US', {
                            style: 'decimal',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) + 'M';
                        },
                        offsetY: -15,
                        style: {
                          fontSize: '13px',
                          fontWeight: 900,
                        },
                      },
                    },
                  },
                },
                yaxis: {
                  title: {
                    text: 'Amount',
                    offsetX: 4,
                  },
                  labels: {
                  formatter: function (value, index) {
                    if (value === 0) {
                      return '0';
                    } else {
                      return '$' + value.toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }) + 'M';
                    }
                  },
                },
                  tickAmount: 5,
                  min: 0,
                  max: maxDataValue
                },
                xaxis: {
                  title: {
                    text: 'Category',
                    position: 'bottom',
                  },
                  categories: graphCategories,
                },
                legend: {
                  position: 'top',
                  horizontalAlign: 'right',
                },
                fill: {
                  opacity: 1
                },
                dataLabels: {
                  enabled: false,
                },
                tooltip: {
                  enabled: true, // Enable tooltips
                  y: {
                    formatter: function (val) {
                      return val.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) + 'M';
                    }
                  }
                }      
              }
        });
      }
    }

    return config;
  }, [irrByCategoryGraph, series, graphCategories]);
  
  const DropDownIcon = () => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
      setAnchorEl(null);
    }

    const handleSelect = (retry=0) => {
        setAnchorEl(null);
        setIrrByCategoryGraphLoading(true)
        if(irrByCategoryGraph.graphType === 'bar' && irrByCategoryGraph.selectedFilter === 'view-irr-category' ) {
            dispatch(setIrrByCategoryViewGraph({ graphType: 'bar', menuText: 'View IRR Category', cardTitle: 'View Gain/Loss By Category', selectedFilter: 'view-gain-loss' }))
            dispatch(resetIrrByCategoryViewGraph())
            let params = {
              organization: getOrganizationIdOFLoggedUser(user),
              quarter_dropdown: filterDate ? lodash.find(dates, function(date) { return date.value === filterDate }).label : null,
              category: filterCategory ? lodash.find(categories, function(category) { return category.value === filterCategory }).label : null,
              entity: filterEntity ? filterEntity : null,
              gain: true,
              ytd: irrCategorySelectorValue === 1,
            }

            dispatch(getIrreByCategory(params))
            .then(({payload}) => {
              if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
                dispatch(getNewAccessToken());
                handleSelect(retry + 1);
              }
              else if(payload.status === 200) {
                setIrrByCategoryGraphLoading(false)
              }
            })
        }
        else if(irrByCategoryGraph.graphType === 'bar' && irrByCategoryGraph.selectedFilter === 'view-gain-loss' ) {
            dispatch(setIrrByCategoryViewGraph({ graphType: 'bar', menuText: 'View gain/loss', cardTitle: 'IRR By Category', selectedFilter: 'view-irr-category' }))
            dispatch(resetIrrByCategoryViewGraph())
            let params = {
                organization: getOrganizationIdOFLoggedUser(user),
                quarter_dropdown: filterDate ? lodash.find(dates, function(date) { return date.value === filterDate }).label : null,
                category: filterCategory ? lodash.find(categories, function(category) { return category.value === filterCategory }).label : null,
                entity: filterEntity ? filterEntity : null,
                gain: false,
                ytd: irrCategorySelectorValue === 1 ,
            }

            dispatch(getIrreByCategory(params))
            .then(({payload}) => {
              if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
                dispatch(getNewAccessToken());
                handleSelect(retry + 1);
              }
              else if(payload.status === 200) {
                setIrrByCategoryGraphLoading(false)
              }
            })
        }
    }

    const fetchViewGainLossData = (retry=0) => {
        const { payload } = dispatch(getYTDIRR({ organization: getOrganizationIdOFLoggedUser(user), gain: true }))
      
        if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
          dispatch(getNewAccessToken());
          fetchViewGainLossData(retry + 1);
        }
    }
    
    return (
        <>
            <svg
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              className='three-dots' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M10 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10C10.8333 9.5398 10.4602 9.16671 10 9.16671C9.53977 9.16671 9.16667 9.5398 9.16667 10C9.16667 10.4603 9.53977 10.8334 10 10.8334Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M10 5.00004C10.4602 5.00004 10.8333 4.62694 10.8333 4.16671C10.8333 3.70647 10.4602 3.33337 10 3.33337C9.53977 3.33337 9.16667 3.70647 9.16667 4.16671C9.16667 4.62694 9.53977 5.00004 10 5.00004Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M10 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8334C10.8333 15.3731 10.4602 15 10 15C9.53977 15 9.16667 15.3731 9.16667 15.8334C9.16667 16.2936 9.53977 16.6667 10 16.6667Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <Menu
              id="basic-menu"
              className="view-bar-line-chart-investment-value-by-category-graph"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
              'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={ () => handleSelect() }>{ irrByCategoryGraph.menuText }</MenuItem>
            </Menu>
        </>
    )
  }

  const handleChangeIrrByCategorySelector = (selectedValue) => {
    setIrrCategorySelectorFilter(selectedValue.value)
  }

  return (
    <div className='graph-card'>
      { irrByCategoryGraphLoading ?
        <Loader height="25rem" />
        :
        <div className='content'>
          <div className='content-header'>
            <div className='content irr-by-category-content'>
              <div className='text-view'>
                <span className='text'>{ irrByCategoryGraph.cardTitle }</span>
              </div>
              <SimpleSelectInput className="irr-by-category-filter select-filter" handleOnChange={ handleChangeIrrByCategorySelector } selectedBackgroundColor="black" selectedColor="white" selectedValue={ lodash.find(irrCategoriesOptions, function(category) { return category.value === irrCategorySelectorValue }) } options={ irrCategoriesOptions } isFormik={false} name="portfolio_category" />
              <div className='dropdown'>
                <DropDownIcon />
              </div>
            </div>
          </div>
          <div id="chart" className='investment-assets-value-by-category-view'>
            { graphConfig && graphConfig.hasOwnProperty('series') && graphConfig.hasOwnProperty('options') && irrByCategoryGraph.graphType === 'bar' && <ReactApexChart className='investment-assets-value-by-category-chart' options={graphConfig.options} series={graphConfig.series} type='bar' height={300} /> }
          </div>
        </div>
      }
    </div>
  )
}

function useGetIrrByCategory(dispatch, user, filterDate, filterCategory, filterEntity, dates, categories, entities, exposureByCategoryGraphSeries, exposureByCategoryGraphCategories, exposureByCategoryGraphLabels, exposureByCategoryGraphColors, exposureByCategoryGraph, irrByCategoryGraph, irrCategorySelectorValue, lodash, setIrrByCategoryGraphLoading) {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if ( ( isInitialMount.current && exposureByCategoryGraph && exposureByCategoryGraph.graphType === 'donut' && exposureByCategoryGraphSeries && exposureByCategoryGraphSeries.length && exposureByCategoryGraphLabels && exposureByCategoryGraphLabels.length && exposureByCategoryGraphColors && exposureByCategoryGraphColors.length ) || ( isInitialMount.current && exposureByCategoryGraph && exposureByCategoryGraph.graphType === 'area' && exposureByCategoryGraphSeries && exposureByCategoryGraphSeries.length && exposureByCategoryGraphCategories && exposureByCategoryGraphCategories)) {
      fetchData(dispatch, user)
      isInitialMount.current = false;
    }
  }, [dispatch, user, exposureByCategoryGraphSeries, exposureByCategoryGraphCategories, exposureByCategoryGraphLabels, exposureByCategoryGraphColors]);

  useEffect(() => {
    if ( (exposureByCategoryGraph && exposureByCategoryGraph.graphType === 'donut' && exposureByCategoryGraphSeries && exposureByCategoryGraphSeries.length && exposureByCategoryGraphLabels && exposureByCategoryGraphLabels.length && exposureByCategoryGraphColors && exposureByCategoryGraphColors.length ) || (exposureByCategoryGraph && exposureByCategoryGraph.graphType === 'area' && exposureByCategoryGraphSeries && exposureByCategoryGraphSeries.length && exposureByCategoryGraphCategories && exposureByCategoryGraphCategories)) {
      setIrrByCategoryGraphLoading(true)
      fetchData(dispatch, user)
    }
  }, [dispatch, user, filterDate, filterCategory, filterEntity, irrCategorySelectorValue]);

  async function fetchData(dispatch, user, retry=0) {
    let params = {
        organization: getOrganizationIdOFLoggedUser(user),
        quarter_dropdown: filterDate ? lodash.find(dates, function(date) { return date.value === filterDate }).label : null,
        category: filterCategory ? lodash.find(categories, function(category) { return category.value === filterCategory }).label : null,
        entity: filterEntity ? filterEntity : null,
        gain: irrByCategoryGraph.selectedFilter === 'view-gain-loss' ? true : false,
        ytd: irrCategorySelectorValue === 1 , 
    }

    const { payload } = await dispatch(getIrreByCategory(params))

    if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
      dispatch(getNewAccessToken());
      fetchData(dispatch, user, retry + 1);
    }
    else if(payload && payload.status === 200) {
      setIrrByCategoryGraphLoading(false)
    }
  }
}

export default IrrByCategoryGraph