import React, { useState, useEffect, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import lodash from 'lodash'

import SearchAbleSingleSelector from '../../../../components/SearchAbleSingleSelector'

function AssetSummaryDetailHeaderSelector(props) {

    const parentContainerRef = useRef(null);

    const assetsSelectorOptions = useSelector(state => state.assetSummary.assetsSelectorOptions)

    const [headerInputSelector, setHeaderInputSelector] = useState(assetsSelectorOptions && assetsSelectorOptions.length > 0 && lodash.find(assetsSelectorOptions, function(option) { return option.value === props.asset_id }))

    useEffect(() => {
        if(assetsSelectorOptions && assetsSelectorOptions.length > 0 && assetsSelectorOptions[0] && !headerInputSelector) {
            setHeaderInputSelector( lodash.find(assetsSelectorOptions, function(option) { return option.value === props.asset_id }))
        }
    }, [assetsSelectorOptions])

    const onSelectInputChange = (selectedOption) => {
        setHeaderInputSelector(selectedOption)
        props.setSelectedAssetIdToViewAssetDetail(selectedOption.value)
        props.setSelectedAssetInvestmentTypeDetail(selectedOption.investment)
        if (selectedOption.investment === false) {
            props.toggleTab('asset_details')
        }
    }

    return (
        <div className='content'>
            <div ref={parentContainerRef} className='selector-view'>
                <SearchAbleSingleSelector adjustableWidth={true} onSelectInputChange={ onSelectInputChange } parentContainerRef={parentContainerRef} showSelectedControlIcon={false} showMenuItemsIcon={false} showAddButtonInMenu={false} inputName={'asset-summary-detail-header'} selectedValue={headerInputSelector} className="selector-input" options={assetsSelectorOptions} />
            </div>
        </div>
    )
}

export default AssetSummaryDetailHeaderSelector