import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PortfolioOverviewBreadCrumbs from './PortfolioOverviewBreadCrumbs';
import PortfolioOverviewHeaderContent from './PortfolioOverviewHeaderContent';
import PortfolioStatSection from './PortfolioStatSection';
import InvestmentValueByCategoryGraph from './InvestmentValueByCategoryGraph';
import IrrOverTimeGraph from './IrrOverTimeGraph';
import ExposureCategoryGraph from './ExposureCategoryGraph';
import IrrByCategoryGraph from './IrrByCategoryGraph';

function PortfolioOverview(props) {

  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)
  const investmentAssetsValueByCategoryViewGraph = useSelector(state => state.portfolio.investmentAssetsValueByCategoryViewGraph)

  return (
    <>
      <div className='header-section'>
        <div className='portfolio-overview-page-container'>
          <div className='portfolio-overview-page-header'>
            <PortfolioOverviewBreadCrumbs />
            <PortfolioOverviewHeaderContent />
          </div>
        </div>
      </div>
      <PortfolioStatSection />
      <div className='graph-section'>
        <div className='graph-section-container'>
          <InvestmentValueByCategoryGraph />
          <IrrOverTimeGraph />
        </div>
        <div className='graph-section-container'>
          <ExposureCategoryGraph />
          <IrrByCategoryGraph />
        </div>
      </div>
    </>
  )
}

export default PortfolioOverview;