import { createSlice  } from '@reduxjs/toolkit'

export const alertSlice = createSlice({
    name: 'alert',
    initialState: {
        confirmationAlertModalShown: false,
        alertModalShown: false,
        alertTitle: '',
        alertMessage: '',
        alertType: '',
        removing_id: '',
        asset_id: '',
        screen: '',
        currentRequestId: '',
        error: "",
    },
    reducers: {
        showConfirmationAlertModal: (state, { payload }) => {
            state.confirmationAlertModalShown = true
            state.alertTitle = payload.alertTitle
            state.alertMessage = payload.alertMessage
            state.alertType = payload.alertType
            state.screen =  payload.screen
            state.removing_id = payload.removing_id
            state.error = payload.error
            state.asset_id = payload.asset_id
        },
        hideConfirmationAlertModal: state => {
            state.confirmationAlertModalShown = false
            state.alertTitle = ''
            state.alertMessage = ''
            state.alertType = ''
            state.removing_id = ''
            state.asset_id = ''
            state.screen = ''
        },
        showAlertModal: (state, { payload }) => {
            state.alertModalShown = true
            state.alertTitle = payload.alertTitle
            state.alertMessage = payload.alertMessage
            state.alertType = payload.alertType
        },
        hideAlertModal: state => {
            state.alertModalShown = false
            state.alertTitle = ''
            state.alertMessage = ''
            state.alertType = ''
        },
    },
    extraReducers(builder) {
    }
})

export const { showConfirmationAlertModal, hideConfirmationAlertModal, showAlertModal, hideAlertModal } = alertSlice.actions
export default alertSlice.reducer