import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableActionButtons from '../../../../components/table/TableActionButtons';

import { showEditOrganizationModal } from '../../../../redux/slices/organization/organizationSlice';
import { getOrganizationDataForEdit } from '../../../../redux/slices/organization/organizationSlice';
import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';
import { showConfirmationAlertModal } from '../../../../redux/slices/alertSlice';

export default function OrganizationTable (props) {

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const getHeaderWidth = (header) => {
    switch (header.col_key) {
      case 'name':
        return '26.0625rem';
      case 'type':
        return '16.375rem';
      case 'main_contact':
        return '16.0625rem';
      default:
        return '';
    }
  };  

  const showEditModal = useCallback((id, retry=0) => {
    dispatch(getOrganizationDataForEdit(id))
    .then(({payload}) => {
      if(payload && payload.status === 200) {
        dispatch(showEditOrganizationModal())
      }
      else if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
        dispatch(getNewAccessToken())
        .then((res) => {
          if(res.payload && res.payload.data && res.payload.data.success) {
            return showEditModal(id, retry + 1)
          }
        })
      }
    })
  }, [])

  const removeOrganization = useCallback((id) => {

    let alert_params = {
      alertTitle: 'Remove Organization',
      alertMessage: 'Are you sure you want to delete the organization?',
      alertType: 'remove_organization',
      removing_id: id,
    }

    dispatch(showConfirmationAlertModal(alert_params))
  }, [])

  const usedAsOrganization = useCallback((organization_id) => {
    localStorage.setItem('organization_id', JSON.stringify(organization_id))
    window.location.href = '/asset-summary';
  }, [])

  return (
    <TableContainer className="table-container" component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
          { props.tableHeader.map((header, index) => (
              <TableCell key={index} style={{ width: getHeaderWidth(header) }} align="left">{ header.col_name }</TableCell>
          ))}
          <TableCell style={{ width: '10rem' }} align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { props.tableData.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" scope="row">{row.name}</TableCell>
              <TableCell align="left">{row.type}</TableCell>
              <TableCell align="left">{row.main_contact}</TableCell>
              <TableCell align="left"><TableActionButtons disable={ false } isRemoveAllow={ true } isEditAllow={ true } isExportAllow={ true } remove={ () => removeOrganization(row.id)} showEditModal={ () => showEditModal(row.id) } usedAsOrganization={ ()  => usedAsOrganization(row.id) } /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}