import React from 'react';
import { styled } from '@mui/material/styles';
import SearchIcon from '../icons/SearchIcon';
import InputBase from '@mui/material/InputBase';

const Search = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '20rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '0.5rem',
    flexShrink: 0,
    position: 'relative',
    borderRadius: '0.5rem',
    border: '1px solid var(--gray-300, #D0D5DD)',
    background: 'var(--base-white, #FFF)',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.375rem',
    alignSelf: 'stretch',
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({

    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    alignSelf: 'stretch',

    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            width: '262px',
            color: '#667085',
        },
    },
    '& .MuiInputBase-input::placeholder': {
        color: '#667085',
        opacity: 1,
    }
}));

export default function SearchBar() {
  return (
    <Search>
        <SearchIconWrapper>
        <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
            placeholder="Search"
            inputProps={{ 'aria-label': 'search' }}
        />
    </Search>
  )
}