import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import lodash from 'lodash'
import { Typography } from "@mui/material";
import { Formik, Field, Form } from 'formik';

import { updatePassword } from '../../redux/slices/account/accountSettingSlice';
import { showAlertModal } from '../../redux/slices/alertSlice';
import { getNewAccessToken } from '../../redux/slices/auth/authSlice';


function AccountPasswordWSetting() {
  
  const dispatch = useDispatch()
  
  const [serverError, setServerError] = useState([])
  
  const formReadyToSubmit = (values) => {
    if(!values.current_password || !values.new_password || !values.confirm_new_password) {
        return false;
    }
    else {
        return true;
    }
  }
  
  const updateUserPassword = (values, resetForm, retry=0) => {
    dispatch(updatePassword(values))
    .then(({payload}) => {
      if(payload && payload.status === 200) {
        resetForm()
        let params = {
          alertTitle: 'Password',
          alertMessage: 'Your password has been updated.',
          alertType: 'update_user_profile',
        }

        dispatch(showAlertModal(params))
      }
      else if(payload && payload.response && payload.response.status === 400) {
        const errorArray = {};
        for( const key in payload.response.data ) {
            if(Object.hasOwnProperty.call(payload.response.data, key)) {
                const value = payload.response.data[key]
                errorArray[key] = value.join('. ')
            }
        }
        setServerError([errorArray])
      }
      else if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
        dispatch(getNewAccessToken())
        .then((res) => {
            if(res.payload && res.payload.data && res.payload.data.success) {
                return updateUserPassword(values, resetForm, retry + 1)
            }
        })
      }
    })
  }
  const validateResetPasswordParameters = (values) => {
        let errors = {}

        if (values.new_password.length < 8) {
            errors.new_password = 'New password must be at least 8 characters.';
        }
        return errors
    }

  return (
    <div className="account-setting-password-form-section">
      <div className="account-setting-password-form-container">
        <Formik
          initialValues={{
              current_password: '',
              new_password: '',
              confirm_new_password: '',
          }}
          validate={validateResetPasswordParameters}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, { resetForm }) => { 
            if(formReadyToSubmit(values)) {
              setServerError([])
              updateUserPassword(values, resetForm)
            }
          }}
        >
          {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
            <Form className="account-setting-password-form">
              <div className="account-setting-password-form-header">
                <div className="account-setting-password-form-header-content">
                  <div className="intro">
                    <Typography className="title">Password</Typography>
                    <Typography className="info">Please enter your current password to change your password.</Typography>
                  </div>
                  <div className="action-buttons">
                    {/* <button type="button" className="cancel">Cancel</button> */}
                    <button type="submit" className={"disable-save-button " + `${ formReadyToSubmit(values) ? 'save' : '' }`}>Save</button>
                  </div>
                </div>
                <div className="divider"></div>
              </div>
              <div className="account-setting-password-form-body">
                <div className="input-content">
                  <Typography className="label">Current password</Typography>
                    <div className="input-field">
                      <Field className="input" type="password" placeholder="........" name="current_password" />
                    </div>
                </div>
                <div className="divider"></div>
                <div className="input-content">
                  <Typography className="label">New password</Typography>
                    <div className="input-field">
                      <Field className="input" type="password" placeholder="........"name="new_password" />
                      <Typography className="new-password-info-message">Your new password must be more than 8 characters.</Typography>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="input-content">
                  <Typography className="label">Confirm new password</Typography>
                    <div className="input-field">
                      <Field className="input" type="password" placeholder="........" name="confirm_new_password" />
                    </div>
                </div>
                  { errors.new_password && <div className="error">{ errors.new_password }</div>}
                { serverError && serverError.map((error, index) => (
                  error[lodash.keys(error)[0]] && <div className="error">{ error[lodash.keys(error)[0]] }</div>
                ))}
                <div className="divider"></div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default AccountPasswordWSetting