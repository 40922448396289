import React from 'react';
import DashboardsIcon from '../../../../components/icons/DashboardsIcon';
import UserRightChevronIcon from '../../../../components/icons/RightChevronIcon';

export default function AssetSummaryBreadCrumbs () {
  return (
    <div className="asset-summary-breadcrumbs">
        <DashboardsIcon color="#667085" />
        <span className="management">Dashboards</span>
        <UserRightChevronIcon />
        <span className="asset-summary">Asset Summary</span>
    </div>
  )
}