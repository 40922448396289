import React from 'react';
import SideBarManagementsIcon from '../../../../components/icons/SideBarManagementsIcon';
import OrganizationRightChevronIcon from '../../../../components/icons/RightChevronIcon';

export default function OrganizationBreadCrumbs () {
  return (
    <div className="organization-breadcrumbs">
        <SideBarManagementsIcon color="#667085" />
        <span className="management">Management</span>
        <OrganizationRightChevronIcon />
        <span className="organization">Organizations</span>
    </div>
  )
}