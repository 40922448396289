import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AssetSummary from './AssetSummary'
import AssetSummaryDetail from './AssetSummaryDetail';

function AssetSummaryPage(props) {

    const dispatch = useDispatch()

    const [asset_id, setSelectedAssetIdToViewAsset] = useState('')
    const [asset_investment_type, setSelectedAssetInvestmentType] = useState('')

    const setSelectedAssetIdToViewAssetDetail = (asset_id) => {
        setSelectedAssetIdToViewAsset(asset_id)
    }

    const setSelectedAssetInvestmentTypeDetail = (asset_investment_type) => {
        setSelectedAssetInvestmentType(asset_investment_type)
    }

    return (
        <>
            { asset_id ?
                <AssetSummaryDetail asset_id={asset_id}
                                    setSelectedAssetIdToViewAssetDetail={ setSelectedAssetIdToViewAssetDetail }
                                    setSelectedAssetInvestmentTypeDetail={ setSelectedAssetInvestmentTypeDetail }
                                    investment_type={ asset_investment_type } />
                :
                <AssetSummary setSelectedAssetIdToViewAssetDetail={ setSelectedAssetIdToViewAssetDetail }
                 setSelectedAssetInvestmentTypeDetail={ setSelectedAssetInvestmentTypeDetail }/>
            }
        </>
    )
}

export default AssetSummaryPage;