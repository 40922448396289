import React,{ useEffect, useState } from 'react'
import lodash from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { Modal, Typography } from "@mui/material";
import AddUserModalHeaderUserIcon from '../../../icons/AddUserModalHeaderUserIcon';
import ModalCloseIcon from '../../../icons/ModalCloseIcon';
import SimpleSelectInput from '../../../commonComponents/SimpleSelectInput';
import MailIcon from '../../../icons/MailIcon';

import { hideAddUserModal } from '../../../../redux/slices/user/userSlice';
import { createNewUser } from '../../../../redux/slices/user/userSlice';
import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';

function AddUserModal() {

    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user)
    const addUserModalShown = useSelector(state => state.user.addUserModalShown)
    const user_organization_list = useSelector(state => state.user.user_organization_list)
    const user_role_list = useSelector(state => state.user.user_role_list)


    const [serverError, setServerError] = useState([])

    useResetServerErrorOnOpen(addUserModalShown, setServerError)

    const closeAddUserModal = () => {
        dispatch(hideAddUserModal())
    }
    function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const formReadyToSubmit = (values) => {
        if(!values.firstname || !values.lastname || !values.email || !values.organization || !values.role ) {
            return false;
        }
        else {
            return true;
        }
    }

    const submitAddNewUser = (values, retry=0) => {
        dispatch(createNewUser(values))
        .then(({payload}) => {
            if(payload && payload.status === 201) {
                closeAddUserModal()
            }
            else if(payload && payload.response && payload.response.status === 400) {
                const errorArray = {};
                for( const key in payload.response.data ) {
                    if(Object.hasOwnProperty.call(payload.response.data, key)) {
                        const value = payload.response.data[key]
                        errorArray[key] = value.join('. ')
                    }
                }
                setServerError([errorArray])
            }
            else if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
                dispatch(getNewAccessToken())
                .then((res) => {
                    if(res.payload && res.payload.data && res.payload.data.success) {
                        return submitAddNewUser(values, retry + 1)
                    }
                })
            }
        })
    }

    return (
        <Modal
            open={addUserModalShown}
            onClose={ closeAddUserModal }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-user-modal-view"
        >
            <div className="add-user-modal">
                <div className="add-user-modal-header">
                    <div className="add-user-modal-header-content">
                        <div className="add-user-modal-header-icons-view">
                            <div className="add-user-modal-header-feature-icon">
                                <AddUserModalHeaderUserIcon />
                            </div>
                            <div className="add-user-modal-header-close-button">
                                <ModalCloseIcon closeModal={ closeAddUserModal } />
                            </div>
                        </div>
                        <div className="add-user-modal-header-text">
                            <Typography className="add-user-modal-header-title-text">Add User</Typography>
                            <Typography className="add-user-modal-header-info-text">Input the details of new user below</Typography>
                        </div>
                    </div>
                </div>
                <div className="add-user-modal-content">
                    <Formik
                        initialValues={{
                            firstname: '',
                            lastname: '',
                            email: '',
                            organization: '',
                            role: '',
                        }}
                        onSubmit={(values) => { 
                            if(formReadyToSubmit(values)) {
                                setServerError([])
                                submitAddNewUser(values)
                            }
                        }}
                    >
                        {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
                            <Form className="add-user-form">
                                <div className="input-field-frame-1">
                                    <div className="input-field-view">
                                        <div className="input-with-label">
                                            <Typography className="input-label">First Name</Typography>
                                            <Field className="user-first-name-input" placeholder="Input first name" name="firstname" />
                                        </div>
                                    </div>
                                    <div className="input-field-view">
                                        <div className="input-with-label">
                                            <Typography className="input-label">Last Name</Typography>
                                            <Field className="user-last-name-input" placeholder="Input last name" name="lastname" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-field-view">
                                    <div className="input-with-label">
                                        <Typography className="input-label">Email</Typography>
                                        <div className="row-input-with-icon-view">
                                            <div className="input-content">
                                                <MailIcon />
                                                <Field className="input-with-icon" placeholder="Input email address" type="email" name="email" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-field-view">
                                    <div className="input-with-label">
                                        <Typography className="input-label">Organization</Typography>
                                        <Field placeholder="Select organization" className="organization-name-input" component={ SimpleSelectInput } selectedBackgroundColor="black" selectedColor="white" isFormik={true} selectedValue={ user_organization_list && values.organization && lodash.find(user_organization_list, function(organization) { return organization.value === values.organization }) } options={ user_organization_list } name="organization" />
                                    </div>
                                </div>
                                <div className="input-field-view">
                                    <div className="input-with-label">
                                        <Typography className="input-label">User Role</Typography>
                                        <Field placeholder="Select user role" className="user-role-input" component={ SimpleSelectInput } selectedBackgroundColor="black" selectedColor="white" isFormik={true} selectedValue={ user_organization_list && values.role && lodash.find(user_role_list, function(role) { return role.value === values.role }) } options={ user_role_list } name="role" />
                                    </div>
                                </div>
                                <div className="add-user-modal-footer">
                                    <div className="add-user-modal-footer-content">
                                        { serverError && serverError.map((error, index) => (
                                            error[lodash.keys(error)[0]] && <div className="error">{ capitalizeFirstLetter(error[lodash.keys(error)[0]]) }</div>
                                        ))}
                                        <div className="add-user-modal-footer-buttons">
                                            <button type="button" onClick={ closeAddUserModal } className="cancel-user-button">Cancel</button>
                                            <button type="submit" className={"disable-add-user-button " + `${ formReadyToSubmit(values) ? 'enable-add-user-button' : '' }`}>Add</button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    )
}

function useResetServerErrorOnOpen(addUserModalShown, setServerError) {
    useEffect(() => {
        if(addUserModalShown) {
            setServerError([])
        }
    }, [addUserModalShown])
}

export default AddUserModal