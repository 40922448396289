import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form } from 'formik';
import { getLoggedUserAccessToken, getLoggedUserRefreshToken } from "../util/commonFunctions";
import { useLocation } from 'react-router-dom';
//redux
import { resetPassword, getNewAccessToken } from "../redux/slices/auth/authSlice";

export default function ResetPassword() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const location = useLocation();
      const queryParams = new URLSearchParams(location.search);
      const paramValue = queryParams.get('token');

    const [resetPasswordSuccessMessage, setResetPasswordSuccessMessage] = useState()
    const [resetPasswordErrorMessage, setResetPasswordErrorMessage] = useState()

    const validateResetPasswordParameters = (values) => {
        let errors = {}

        if(!values.password) {
            errors.password = 'Please provide new password';
        }
        else if(values.password.length < 8) {
            errors.password = 'Password must be at least 8 characters.';
        }
        if(!values.confirm_password ) {
            errors.confirm_password = 'Please provide confirm new password';
        }
        else if(values.confirm_password !== values.password ) {
            errors.confirm_password = 'Password mismatch';
        }

        return errors
    }

    return (
        <React.Fragment>
            <div className="reset-password-container">
                <div className="reset-password-page">
                    <Col md={{ span: 12 }}>
                        <Formik
                            //initializing the local attributes of form
                            initialValues={{
                                password: '',
                                confirm_password: '',
                                token: paramValue
                            }}
                            validate={validateResetPasswordParameters}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values) => {
                                setResetPasswordSuccessMessage('')
                                setResetPasswordErrorMessage('')
                                dispatch(resetPassword({ token: values.token, password: values.password, confirm_password: values.confirm_password, access_token: '' }))
                                .then((res) => {
                                    if(res.payload && res.payload.data.success) {
                                        setResetPasswordSuccessMessage(res.payload.data.message)
                                    }
                                    else if(res.payload && !res.payload.data.success) {
                                        setResetPasswordErrorMessage(res.payload.data.message)
                                    }
                                })
                            }}
                        >
                            {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
                                <Form>
                                    <Row className="text-center mb-4">
                                        <Col md={{ span: 12 }}>
                                            <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="5" y="5" width="56" height="56" rx="28" fill="#F2F4F7"/>
                                                <path d="M38.8333 29.4999C38.8333 28.9028 38.6055 28.3057 38.1499 27.8501C37.6943 27.3945 37.0972 27.1667 36.5 27.1667M36.5 36.5C40.366 36.5 43.5 33.366 43.5 29.5C43.5 25.634 40.366 22.5 36.5 22.5C32.634 22.5 29.5 25.634 29.5 29.5C29.5 29.8193 29.5214 30.1336 29.5628 30.4415C29.6309 30.948 29.6649 31.2013 29.642 31.3615C29.6181 31.5284 29.5877 31.6184 29.5055 31.7655C29.4265 31.9068 29.2873 32.046 29.009 32.3243L23.0467 38.2866C22.845 38.4884 22.7441 38.5893 22.6719 38.707C22.608 38.8114 22.5608 38.9252 22.5322 39.0442C22.5 39.1785 22.5 39.3212 22.5 39.6065V41.6333C22.5 42.2867 22.5 42.6134 22.6272 42.863C22.739 43.0825 22.9175 43.261 23.137 43.3728C23.3866 43.5 23.7133 43.5 24.3667 43.5H27.1667V41.1667H29.5V38.8333H31.8333L33.6757 36.991C33.954 36.7127 34.0932 36.5735 34.2345 36.4945C34.3816 36.4123 34.4716 36.3819 34.6385 36.358C34.7987 36.3351 35.052 36.3691 35.5585 36.4372C35.8664 36.4786 36.1807 36.5 36.5 36.5Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <rect x="5" y="5" width="56" height="56" rx="28" stroke="#F9FAFB" strokeWidth="10"/>
                                            </svg>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col className="text-center" md={{ span: 12 }}>
                                            <h4>Set new password</h4>
                                            <span className='reset-password-message-text'>Your new password must be different to previously used passwords.</span>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col md={{ span: 12 }} className="reset-password-formik-input-fields">
                                            <div className="mb-4">
                                                { errors.password && <div className="error">{ errors.password }</div>}
                                                <label>New Password</label>
                                                <Field className="form-control" placeholder="Enter New Password" type="password" name="password" />
                                                <span className="create-password-info-message mb-4">Must be at least 8 characters.</span>
                                            </div>
                                            <div className="">
                                                { errors.confirm_password && <div className="error">{ errors.confirm_password }</div>}
                                                <label>Confirm New Password</label>
                                                <Field className="form-control" placeholder="Confirm New Password" type="password" name="confirm_password" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={{ span: 12 }}  >
                                            { resetPasswordSuccessMessage && <div className="success">{ resetPasswordSuccessMessage }</div> }
                                            { resetPasswordErrorMessage && <div className="error">{ resetPasswordErrorMessage }</div>}
                                            <button type="submit" className="btn reset-button mb-4">Reset password</button>
                                            <div className="back-to-login-page-view">
                                                <svg className="" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.3334 6.99999H1.66669M1.66669 6.99999L7.50002 12.8333M1.66669 6.99999L7.50002 1.16666" stroke="#475467" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                                <span onClick={ () => navigate('/login', { replace: true }) } className='back-to-login'>Back to log in</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </div>
            </div>
        </React.Fragment>
    );
}