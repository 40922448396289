import React from 'react';
import TrashIcon from '../icons/TrashIcon';
import EditIcon from '../icons/EditIcon';
import ExportLogin from '../icons/ExportLogin';
import FileIcon from '../icons/FileIcon';
import Tooltip from '@mui/material/Tooltip';
import IconToolTip from '../IconToolTip';
import IconButton from '@mui/material/IconButton';


export default function TableActionButtons(props) {
  return (
    <div className={'d-flex ' + `${props.disable ? 'disable-action-buttons' : ''}` }>
        { props.isFileAllow && props.showToolTip && <IconToolTip viewable={props.viewable} toolTipData={props.toolTipData} /> }
        { props.isRemoveAllow && <TrashIcon disable={ props.disable } remove={ props.remove } marginRight={ 24 } /> }
        { props.isEditAllow && <EditIcon disable={ props.disable } showEditModal={ props.showEditModal } marginRight={ 24 } /> }
        { props.isExportAllow && <ExportLogin disable={ props.disable } usedAsOrganization={ props.usedAsOrganization } /> }
    </div>
  )
}