import React, { useState, useEffect, useRef, useCallback } from 'react';
import lodash from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableActionButtons from '../../../components/table/TableActionButtons';
import Select, { components } from 'react-select';
import { getOrganizationIdOFLoggedUser, isAddAssetAllowedToLoggedUser } from '../../../util/commonFunctions';

import { getNewAccessToken } from '../../../redux/slices/auth/authSlice';
import { getAssetEventDetail, getEditEventInitialData, getAssetEvents, showEditEventModal, getEventsInitialData } from '../../../redux/slices/assets/assetSummarySlice';
import { getOrganizationEvents } from '../../../redux/slices/eventdatabase/eventDataBaseSlice';
import { showConfirmationAlertModal } from '../../../redux/slices/alertSlice';

function EventDataBaseTable(props) {

    const dispatch = useDispatch()

    const user = useSelector(state => state.auth.user)
    const eventsDatabaseTableColumns = useSelector(state => state.eventDataBase.events_database_table_columns)
    const organizationEvents = useSelector(state => state.eventDataBase.organizationEvents)

    useGetAssetsEvents(dispatch, user)

    const getDisplayedValue = (value, name) => {
      switch (name) {
        case 'date':
        case 'type':
        case 'name':
          return value ? value : '---';
        case 'total_amount':
          const formattedNumber = Math.abs(value);
          let formattedValue = value === 0 ? '---' : value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2});
          return formattedValue
        case 'share_price':
          return value ? '$' + value.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) : '---';
        default:
          return '---';
      }
    };

    const getHeaderWidth = (header) => {
      switch (header.col_key) {
        case 'date':
          return '15.5625rem';
        case 'name':
          return '17.4375rem';
        case 'type':
          return '16.3125rem';
        case 'amount':
          return '16.125rem';
        case 'share_price':
          return '13.0625rem';
        default:
          return '';
      }
    };

    const showEditModal = useCallback((id, retry=0) => {

      let params = {
        asset_id: props.asset_id,
        organization: getOrganizationIdOFLoggedUser(user),
        event_id: id,
      }
      dispatch(getEditEventInitialData(params))
      .then(({payload}) => {
        if(payload && payload.status === 200) {
          let params = {
            organization: getOrganizationIdOFLoggedUser(user),
            asset_id: props.asset_id,
            event_id: id,
          }

          dispatch(showEditEventModal({selectedAssetId: payload.data.selected_event_data.asset_id, editEventScreenName: 'event'}))
        }
        else if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
          dispatch(getNewAccessToken())
          .then((res) => {
            if(res.payload && res.payload.data && res.payload.data.success) {
              return showEditModal(id, retry + 1)
            }
          })
        }
      })
    }, [])

    const removeUser = useCallback((id, asset_id) => {
      let event = lodash.find(organizationEvents, function(event) { return event.id === id })
      let alert_params = {
        alertTitle: 'Delete Event',
        alertMessage: 'Are you sure you want to delete event: ' + event.date + '?',
        alertType: 'remove_event',
        screen: 'event',
        removing_id: id,
        organization: getOrganizationIdOFLoggedUser(user),
        asset_id: asset_id,
      }

      dispatch(showConfirmationAlertModal(alert_params))
    }, [organizationEvents, user, props.asset_id])

    return (
      <TableContainer className="event-database-table" component={Paper}>
          <Table aria-label="simple table">
          <TableHead>
              <TableRow>
              { eventsDatabaseTableColumns && eventsDatabaseTableColumns.map((header, index) => (
                  <TableCell key={index} style={{ width: getHeaderWidth(header) }} align="left">{ header.col_name }</TableCell>
              ))}
              <TableCell style={{ width: '10rem' }} align="left">Notes</TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
              { organizationEvents && organizationEvents.map((row, index) => (
              <TableRow
                  className='table-data-row'
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                  <TableCell align="left" scope="row">{ getDisplayedValue(row.date, 'date') }</TableCell>
                  <TableCell align="left">{ getDisplayedValue(row.name, 'name') }</TableCell>
                  <TableCell align="left">{ getDisplayedValue(row.type, 'type') }</TableCell>
                  <TableCell align="left">{ getDisplayedValue(row.total_amount, 'total_amount') }</TableCell>
                  <TableCell align="left">{ getDisplayedValue(row.share_price, 'share_price') }</TableCell>
                  <TableCell align="left">{<TableActionButtons toolTipData={row.notes} disable={ false } isFileAllow={ true } viewable={ row.viewable } showToolTip={ true } isRemoveAllow={ isAddAssetAllowedToLoggedUser(user) } isEditAllow={ isAddAssetAllowedToLoggedUser(user) } isExportAllow={ false } remove={ () => removeUser(row.id, row.asset_id)} showEditModal={ () => showEditModal(row.id) } />}</TableCell>
              </TableRow>
              ))}
          </TableBody>
          </Table>
      </TableContainer>
    );
}

export default EventDataBaseTable

async function getEvents(dispatch, user, retry = 0) {
  let params = {
    organization: getOrganizationIdOFLoggedUser(user)
  }

  const { payload } = await dispatch(getOrganizationEvents(params))

  if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
    await dispatch(getNewAccessToken());

    return getEvents(dispatch, user, retry + 1);
  }

  return payload
}

function useGetAssetsEvents(dispatch, user) {
  const isMountRef = useRef(true);
  useEffect(() => {
    if(isMountRef.current) {
      const fetchEvents = async () => {
        const usersData = await getEvents(dispatch, user);
      };
  
      fetchEvents();
      isMountRef.current = false;
    }
  }, []);
}