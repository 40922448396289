import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EventDataBaseBreadCrumbs from './EventDataBaseBreadCrumbs';
import AssetSummaryHeaderContent from '../dashboards/assets/AssetSummaryHeaderContent';
import EventDataBaseHeaderContent from './EventDataBaseHeaderContent';
import AssetSummaryTable from '../dashboards/assets/AssetSummaryTable';
import { getOrganizationIdOFLoggedUser } from '../../../util/commonFunctions';
import EventDataBaseTable from './EventDataBaseTable';

import { getNewAccessToken } from '../../../redux/slices/auth/authSlice';
import { getAssetSummary } from '../../../redux/slices/assets/assetSummarySlice';

function EventsDatabase(props) {

  const dispatch = useDispatch()

  return (
    <>
      <div className='header-section'>
        <div className='event-database-page-container'>
        <div className='event-database-page-header'>
          <EventDataBaseBreadCrumbs />
          <EventDataBaseHeaderContent />
        </div>
        </div>
      </div>
      <EventDataBaseTable />
    </>
  )
}

export default EventsDatabase;