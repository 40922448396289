import React from 'react'

function UserIcon() {
  return (
    <div className="user-icon-view">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M12.8 13.3996C12.8 12.5623 12.8 12.1436 12.6967 11.8029C12.464 11.0359 11.8637 10.4356 11.0967 10.2029C10.756 10.0996 10.3373 10.0996 9.50001 10.0996H6.50001C5.66267 10.0996 5.244 10.0996 4.90333 10.2029C4.13628 10.4356 3.53604 11.0359 3.30336 11.8029C3.20001 12.1436 3.20001 12.5623 3.20001 13.3996M10.7 5.29961C10.7 6.79078 9.49118 7.99961 8.00001 7.99961C6.50884 7.99961 5.30001 6.79078 5.30001 5.29961C5.30001 3.80844 6.50884 2.59961 8.00001 2.59961C9.49118 2.59961 10.7 3.80844 10.7 5.29961Z" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </div>
  )
}

export default UserIcon