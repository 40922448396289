import React from 'react'

function AddAssetProgressCompletedIcon() {
  return (
    <svg className='progress-completed-icon' xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.6199 7.39016L10.4599 14.3002L8.55994 12.2702C8.20994 11.9402 7.65994 11.9202 7.25994 12.2002C6.86994 12.4902 6.75994 13.0002 6.99994 13.4102L9.24994 17.0702C9.46994 17.4102 9.84994 17.6202 10.2799 17.6202C10.6899 17.6202 11.0799 17.4102 11.2999 17.0702C11.6599 16.6002 18.5299 8.41016 18.5299 8.41016C19.4299 7.49016 18.3399 6.68016 17.6199 7.38016V7.39016Z" fill="white"/>
    </svg>
  )
}

export default AddAssetProgressCompletedIcon