import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OrganizationBreadCrumbs from './OrganizationBreadCrumbs';
import OrganizationHeaderContent from './OrganizationHeaderContent';
import OrganizationTable from './OrganizationTable';
import { getOrganizationList } from '../../../../redux/slices/organization/organizationSlice';
import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';

function Organization(props) {

  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)
  const organizations = useSelector(state => state.organization.organizations)
  const organizations_table_columns = useSelector(state => state.organization.organizations_table_columns)

  useGetOrganizationsList(dispatch, user)

  return (
    <>
      <div className='header-section'>
        <div className='organization-page-container'>
        <div className='organization-page-header'>
          <OrganizationBreadCrumbs />
          <OrganizationHeaderContent />
        </div>
        </div>
      </div>
      { organizations && organizations_table_columns && <OrganizationTable tableData={ organizations } tableHeader={ organizations_table_columns } /> }
    </>
  )
}

async function getOrganizations(dispatch, user, retry = 0) {
  const { payload } = await dispatch(getOrganizationList())
  if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
    await dispatch(getNewAccessToken());

    return getOrganizations(dispatch, user, retry + 1);
  }
}

function useGetOrganizationsList(dispatch, user) {
  const isMountRef = useRef(true);
  useEffect(() => {
    if(isMountRef.current) {
      const fetchOrganizations = async () => {
        const organizationsData = await getOrganizations(dispatch, user);
      };
  
      fetchOrganizations();
      isMountRef.current = false;
    }
  }, []);
}

export default Organization;