import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import lodash from 'lodash'
import { Typography } from "@mui/material";
import { Formik, Field, Form } from 'formik';
import MailIcon from '../../components/icons/MailIcon';
import { getNewAccessToken } from '../../redux/slices/auth/authSlice';

import { getUserProfileData, updateUserProfile } from '../../redux/slices/account/accountSettingSlice';
import { showAlertModal } from '../../redux/slices/alertSlice';

function AccountProfileSettingForm() {

  const dispatch = useDispatch()

  const profileUserData = useSelector(state => state.accountSetting.profileUserData)

  const [serverError, setServerError] = useState([])

  useGetUserProfileData(dispatch)

  const formReadyToSubmit = (values) => {
    if(!values.firstname || !values.lastname || !values.email) {
        return false;
    }
    else {
        return true;
    }
  }

  const updateProfile = (values, retry=0) => {
    dispatch(updateUserProfile(values))
    .then(({payload}) => {
      if(payload && payload.status === 200) {
        let params = {
          alertTitle: 'Personal info',
          alertMessage: 'Your personal info has been updated.',
          alertType: 'user_profile',
        }
        dispatch(showAlertModal(params))
      }
      else if(payload && payload.response && payload.response.status === 400) {
        const errorArray = {};
        for( const key in payload.response.data ) {
            if(Object.hasOwnProperty.call(payload.response.data, key)) {
                const value = payload.response.data[key]
                errorArray[key] = value.join('. ')
            }
        }
        setServerError([errorArray])
      }
      else if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
          dispatch(getNewAccessToken())
          .then((res) => {
              if(res.payload && res.payload.data && res.payload.data.success) {
                  return updateProfile(values, retry + 1)
              }
          })
      }
    })
  }

  return (
    <div className="account-setting-profile-form-section">
      <div className="account-setting-profile-form-container">
        <Formik
          enableReinitialize={true}
          initialValues={{
              firstname: profileUserData.firstname || '',
              lastname: profileUserData.lastname || '',
              email: profileUserData.email || ''
          }}
          onSubmit={(values) => { 
            if(formReadyToSubmit(values)) {
              setServerError([])
              updateProfile(values)
            }
          }}
        >
          {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
            <Form className="account-setting-profile-form">
              <div className="account-setting-profile-form-header">
                <div className="account-setting-profile-form-header-content">
                  <div className="intro">
                    <Typography className="title">Personal info</Typography>
                    <Typography className="info">Update your personal details here.</Typography>
                  </div>
                  <div className="action-buttons">
                    {/* <button type="button" className="cancel">Cancel</button> */}
                    <button type="submit" className={"disable-save-button " + `${ formReadyToSubmit(values) ? 'save' : '' }`}>Save</button>
                  </div>
                </div>
                <div className="divider"></div>
              </div>
              <div className="account-setting-profile-form-body">
                <div className="name-input-content">
                  <Typography className="label">Name</Typography>
                  <div className="input-fields">
                    <div className="input-field">
                      <Field className="input" placeholder="Input first name" name="firstname" />
                    </div>
                    <div className="input-field">
                      <Field className="input" placeholder="Input last name" name="lastname" />
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="email-input-content">
                  <Typography className="label">Email address</Typography>
                    <div className="input-with-icon-view">
                        <div className="input-content">
                          <MailIcon />
                          <Field className="input-with-icon" placeholder="Input email address" type="email" name="email" />
                      </div>
                    </div>
                </div>
                { serverError && serverError.map((error, index) => (
                  error[lodash.keys(error)[0]] && <div className="error">{ error[lodash.keys(error)[0]] }</div>
                ))}
                <div className="divider"></div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

async function getUserProfile(dispatch, retry = 0) {
  const { payload } = await dispatch(getUserProfileData())

  if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
    await dispatch(getNewAccessToken());

    return getUserProfile(dispatch, retry + 1);
  }

  return payload
}

function useGetUserProfileData(dispatch) {
  const isMountRef = useRef(true);
  useEffect(() => {
    if(isMountRef.current) {
      const fetchUserProfile = async () => {
        const usersData = await getUserProfile(dispatch);
      };
  
      fetchUserProfile();
      isMountRef.current = false;
    }
  }, []);
}

export default AccountProfileSettingForm