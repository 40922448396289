import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SearchBar from '../../../../components/commonComponents/SearchBar';
import UserFilterIcon from '../../../../components/icons/FilterButtonIcon';
import AddAssetIcon from '../../../../components/icons/AddAssetIcon';
import { isAddAssetAllowedToLoggedUser, getOrganizationIdOFLoggedUser } from '../../../../util/commonFunctions';

import { showAddAssetModal, getAddAssetInitialData } from '../../../../redux/slices/assets/assetSummarySlice';

export default function AssetSummaryHeaderContent() {

  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)

  const openAddAssetModal = () => {
    dispatch(getAddAssetInitialData({ organization: getOrganizationIdOFLoggedUser(user) }))
    dispatch(showAddAssetModal())
  }

  return (
    <div className="asset-summary-header-content">
      <span className="manage-asset-summary-text">Asset Summary</span>
      <SearchBar />
      <div className="asset-summary-table-filters">
        <button className="filters-button"><UserFilterIcon />Filters</button>
        { isAddAssetAllowedToLoggedUser(user) && <button className="add-asset-summary-button" onClick={ openAddAssetModal }><AddAssetIcon />Add Asset</button> }
      </div>
    </div>
  )
}