import React,{ useEffect, useState } from 'react'
import lodash from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import {  Modal, Typography } from "@mui/material";
import AddOrganizationModalHeaderBuildingIcon from '../../../icons/AddOrganizationModalHeaderBuildingIcon';
import ModalCloseIcon from '../../../icons/ModalCloseIcon';

import { hideAddOrganizationModal } from '../../../../redux/slices/organization/organizationSlice';
import { createNewOrganization } from '../../../../redux/slices/organization/organizationSlice';
import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';

function AddOrganizationModal() {

    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user)
    const addOrganizationModalShown = useSelector(state => state.organization.addOrganizationModalShown)

    const [serverError, setServerError] = useState([])

    useResetServerErrorOnOpen(addOrganizationModalShown, setServerError)

    const closeAddOrganizationModal = () => {
        dispatch(hideAddOrganizationModal())
    }

    function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const formReadyToSubmit = (values) => {
        if(!values.name || !values.type || !values.main_contact ) {
            return false;
        }
        else {
            return true;
        }
    }

    const submitAddNewOrganization = (values, retry=0) => {
        dispatch(createNewOrganization(values))
        .then(({payload}) => {
            if(payload && payload.status === 201) {
                closeAddOrganizationModal()
            }
            else if(payload && payload.response && payload.response.status === 400) {
                const errorArray = {};
                for( const key in payload.response.data ) {
                    if(Object.hasOwnProperty.call(payload.response.data, key)) {
                        const value = payload.response.data[key]
                        errorArray[key] = value.join('. ')
                    }
                }
                setServerError([errorArray])
            }
            else if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
                dispatch(getNewAccessToken())
                .then((res) => {
                    if(res.payload && res.payload.data && res.payload.data.success) {
                        return submitAddNewOrganization(values, retry + 1)
                    }
                })
            }
        })
    }

    return (
        <Modal
            open={addOrganizationModalShown}
            onClose={ closeAddOrganizationModal }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-organization-modal-view"
        >
            <div className="add-organization-modal">
                <div className="add-organization-modal-header">
                    <div className="add-organization-modal-header-content">
                        <div className="add-organization-modal-header-icons-view">
                            <div className="add-organization-modal-header-feature-icon">
                                <AddOrganizationModalHeaderBuildingIcon />
                            </div>
                            <div className="add-organization-modal-header-close-button">
                                <ModalCloseIcon closeModal={ closeAddOrganizationModal } />
                            </div>
                        </div>
                        <div className="add-organization-modal-header-text">
                            <Typography className="add-organization-modal-header-title-text">Add Organization</Typography>
                            <Typography className="add-organization-modal-header-info-text">Input the details of your organization below</Typography>
                        </div>
                    </div>
                </div>
                <div className="add-organization-modal-content">
                    <Formik
                        initialValues={{
                            name: '',
                            type: '',
                            main_contact: '',
                        }}
                        onSubmit={(values) => { 
                            if(formReadyToSubmit(values)) {
                                setServerError([])
                                submitAddNewOrganization(values)
                            }
                        }}
                    >
                        {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
                            <Form className="add-organization-form">
                                <div className="input-field-view">
                                    <div className="input-with-label">
                                        <Typography className="input-label">Organization Name</Typography>
                                        <Field className="organization-name-input" placeholder="What is the organization’s name?" name="name" />
                                    </div>
                                </div>
                                <div className="input-field-view">
                                    <div className="input-with-label">
                                        <Typography className="input-label">Firm Type</Typography>
                                        <Field className="organization-name-input" placeholder="What type of organization are you adding?" name="type" />
                                    </div>
                                </div>
                                <div className="input-field-view">
                                    <div className="input-with-label">
                                        <Typography className="input-label">Main Contact Person</Typography>
                                        <Field className="organization-name-input" placeholder="Input contact person" name="main_contact" />
                                    </div>
                                </div>
                                <div className="add-organization-modal-footer">
                                    <div className="add-organization-modal-footer-content">
                                        { serverError && serverError.map((error, index) => (
                                            error[lodash.keys(error)[0]] && <div className="error">{ capitalizeFirstLetter(error[lodash.keys(error)[0]]) }</div>
                                        ))}
                                        <div className="add-organization-modal-footer-buttons">
                                            <button type="button" onClick={ closeAddOrganizationModal } className="cancel-organization-button">Cancel</button>
                                            <button type="submit" className={"disable-add-organization-button " + `${ formReadyToSubmit(values) ? 'enable-add-organization-button' : '' }`}>Add</button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    )
}

function useResetServerErrorOnOpen(addOrganizationModalShown, setServerError) {
    useEffect(() => {
        if(addOrganizationModalShown) {
            setServerError([])
        }
    }, [addOrganizationModalShown])
}

export default AddOrganizationModal