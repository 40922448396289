import { Collapse, ListItemButton, ListItemIcon, ListItemText, Typography, Box } from "@mui/material";
import loadash from 'lodash'
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import colorConfigs from "../configs/sidebar/colorConfigs";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import SidebarItem from "./SidebarItem";

export default function SidebarItemCollapse ({ item }) {

  const location = useLocation();
  const [open, setOpen] = useState(false);

  const appState  = location.pathname

  useEffect(() => {
    if (appState && item.parent && loadash.find(item.child, function(nav_item) { return nav_item.path === appState })) {
      setOpen(true);
    }
    else if (appState && !item.parent && item.path === appState ) {
      setOpen(true);
    }
  }, [appState]);

  return (
    item.sidebarProps ? (
      <>
        <Box
          display='flex'
          padding='0rem 1.2rem 0rem 0.8rem'
          alignItems='center'
          gap='0.25rem'
          alignSelf='stretch'
        >
          <ListItemButton
            onClick={() => setOpen(!open)}
            sx={{
              display: 'flex',
              padding: '0.5rem 0.75rem',
              alignItems: 'center',
              gap: '0.5rem',
              alignSelf: 'stretch',
            
              '& .sidebar-icon': {
                stroke: colorConfigs.sidebar.iconStroke,
              }
            }}
          >
            <Box
              display='flex'
              alignItems='center'
              gap='0.75rem'
              flex='1 0 0'
            >
              <ListItemIcon sx={{
                color: colorConfigs.sidebar.color,
                minWidth: 0,
              }}>
                {item.sidebarProps.icon && item.sidebarProps.icon}
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography style={{ color: 'var(--gray-700, #344054)', fontFamily: 'Inter', fontSize: '1rem', fontStyle: 'normal', fontWeight: 600, lineHeight: '1.5rem' }}>
                    {item.sidebarProps.displayText}
                  </Typography>
                }
              />
            </Box>
            {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
          </ListItemButton>
        </Box>
        <Collapse in={open} timeout="auto"
          sx={{
            display: 'flex',
            padding: '0rem 0.625rem 0.5rem 0.5rem',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '0.25rem',
            alignSelf: 'stretch',
          }}
        >
          {item.child?.map((route, index) => (
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SidebarItem single={false} item={route} key={index} />
              )
            ) : null
          ))}
        </Collapse>
      </>
    ) : null
  );
};