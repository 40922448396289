import React from 'react';
import SideBarManagementsIcon from '../../../../components/icons/SideBarManagementsIcon';
import UserRightChevronIcon from '../../../../components/icons/RightChevronIcon';

export default function UserBreadCrumbs () {
  return (
    <div className="user-breadcrumbs">
        <SideBarManagementsIcon color="#667085" />
        <span className="management">Management</span>
        <UserRightChevronIcon />
        <span className="user">Users</span>
    </div>
  )
}