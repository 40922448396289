import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getLoggedUserInitialRoute } from "./commonFunctions";

export default function PublicRoute(props) {

    const navigate = useNavigate();

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const accessToken = useSelector(state => state.auth.accessToken)
    const user = useSelector(state => state.auth.user)

    useEffect(() => {
        if (localStorage.getItem('access_token') && user && user.hasOwnProperty('id')) {
            setIsLoggedIn(true);
            return navigate(getLoggedUserInitialRoute(user),  { replace: true });
        }

        setIsLoggedIn(false);

    }, [accessToken]);
    
    return (
        <React.Fragment>
            {
                !isLoggedIn ? props.children : null
            }
        </React.Fragment>
    );
}