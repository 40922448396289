import React from 'react'

function CircleClearIcon(props) {
  return (
    <svg onClick={ props.callMethod } className='clear-icon' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clipPath="url(#clip0_270_45374)">
            <path d="M12.4998 7.49984L7.49984 12.4998M7.49984 7.49984L12.4998 12.4998M18.3332 9.99984C18.3332 14.6022 14.6022 18.3332 9.99984 18.3332C5.39746 18.3332 1.6665 14.6022 1.6665 9.99984C1.6665 5.39746 5.39746 1.6665 9.99984 1.6665C14.6022 1.6665 18.3332 5.39746 18.3332 9.99984Z" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_270_45374">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
        </defs>
    </svg>
  )
}

export default CircleClearIcon