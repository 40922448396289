import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../components/commonComponents/Loader';
import { getOrganizationIdOFLoggedUser } from '../../../../util/commonFunctions';

import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';
import { getAssetDetails } from '../../../../redux/slices/assets/assetSummarySlice';

function AssetDetails(props) {

  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)
  const assetsWithDetails = useSelector(state => state.assetSummary.assetsWithDetails)

  const [assetsWithDetailsLoading, setAssetsWithDetailsLoading] = useState(true)

  useGetAssetsDetails(dispatch, props.asset_id, user, setAssetsWithDetailsLoading)

  const getDisplayedValue = (value, name) =>{
    if(name === 'sponsor') {
      return value ? value : '---'
    }
    else if(name === 'asset_class') {
      return value ? value : '---'
    }
    else if(name === 'sub_asset_class') {
      return value ? value : '---'
    }
    else if(name === 'sector') {
      return value ? value : '---'
    }
    else if(name === 'investment_type') {
      return value ? value : '---'
    }
    else if(name === 'vintage') {
      return value ? value : '---'
    }
    else if(name === 'commitment') {
      const formattedNumber = Math.abs(value);
      let formattedValue = value === 0 ? '---' : value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2});
      return formattedValue
    }
    else if(name === 'invested') {
      const formattedNumber = Math.abs(value);
      let formattedValue = value === 0 ? '---' : value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2});
      return formattedValue
    }
    else if(name === 'returned') {
      const formattedNumber = Math.abs(value);
      let formattedValue = value === 0 ? '---' : value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2});
      return formattedValue
    }
    else if(name === 'current_value') {
      const formattedNumber = Math.abs(value);
      let formattedValue = value === 0 ? '---' : value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2});
      return formattedValue
    }
  }

  return (
    (
    assetsWithDetailsLoading ?
      <Loader height="40rem" />
      :
    assetsWithDetails && assetsWithDetails.hasOwnProperty('id') &&
      <div className='asset-details-table'>
        <div className='content'>
          <div className='column-1'>
            <span className='cell'>Manager/Sponsor</span>
            <span className='cell'>Asset Class</span>
            <span className='cell'>Sub-Asset Class</span>
            <span className='cell'>Sector</span>
            <span className='cell'>Investment Type</span>
            <span className='cell'>Vintage</span>
            <span className='cell'>Total Commitment</span>
            <span className='cell'>Invested Capital</span>
            <span className='cell'>Capital Returned</span>
            <span className='cell'>Current Remaining Value</span>
          </div>
          <div className='column-2'>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.sponsor, 'sponsor') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.asset_class, 'asset_class') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.sub_asset_class, 'sub_asset_class') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.sector, 'sector') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.investment_type, 'investment_type') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.vintage, 'vintage') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.commitment, 'commitment') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.invested, 'invested') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.returned, 'returned') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.current_value, 'current_value') }</span>
          </div>
        </div>
      </div>
    )
  )
}

async function getAssetsDetails(dispatch, asset_id, user, setAssetsWithDetailsLoading, retry = 0) {
  let params = {
    organization: getOrganizationIdOFLoggedUser(user),
    asset_id: asset_id,
  }

  const { payload } = await dispatch(getAssetDetails(params))

  if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
    await dispatch(getNewAccessToken());

    // Retry the "get organization data" call with the new token
    return getAssetsDetails(dispatch, asset_id, user, setAssetsWithDetailsLoading, retry + 1);
  }
  else if(payload && payload.status === 200) {
    setAssetsWithDetailsLoading(false)
  }

  return payload
}

function useGetAssetsDetails(dispatch, asset_id, user, setAssetsWithDetailsLoading) {
  const isInitialMount = useRef(true);
  useEffect(() => {
    // This block will execute when the component re-renders due to asset_id changes
    const fetchAssetsDetails = async () => {
      const usersData = await getAssetsDetails(dispatch, asset_id, user, setAssetsWithDetailsLoading);
    };
    setAssetsWithDetailsLoading(true)
    fetchAssetsDetails();
  }, [asset_id]);
}

export default AssetDetails