import { createSlice , createAsyncThunk } from '@reduxjs/toolkit'
import Http from '../../../util/Http'
import lodash from 'lodash'
import { getLoggedUserAccessToken } from '../../../util/commonFunctions'

export const getPortfolioInitialData = createAsyncThunk('PORTFOLIO/getPortfolioInitialData', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/portfolio-intial-api/'+ values.organization, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getTotalAssets = createAsyncThunk('PORTFOLIO/getTotalAssets', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/total-assets/'+ values.organization, {
            params: {
                entity: values.entity,
                quarter_dropdown: values.quarter_dropdown
            },
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getInvestmentAssets = createAsyncThunk('PORTFOLIO/getInvestmentAssets', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/total-investment-assets/'+ values.organization, {
            params: {
                entity: values.entity,
                quarter_dropdown: values.quarter_dropdown
            },
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getYTDIRR = createAsyncThunk('PORTFOLIO/getYTDIRR', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/ytd-irr/'+ values.organization, {
            params: {
                gain: values.gain,
                entity: values.entity,
                quarter_dropdown: values.quarter_dropdown
            },
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getAllTimeIRR = createAsyncThunk('PORTFOLIO/getAllTimeIRR', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/alltime-irr/'+ values.organization, {
            params: {
                gain: values.gain,
                entity: values.entity,
                quarter_dropdown: values.quarter_dropdown
            },
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getInvestedAssetsValueByCategory = createAsyncThunk('PORTFOLIO/getInvestedAssetsValueByCategory', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/investment-assets-graph/'+ values.organization, {
            params: {
                category: values.category,
                entity: values.entity,
                quarter_dropdown: values.quarter_dropdown
            },
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getIrrOverTime = createAsyncThunk('PORTFOLIO/getIrrOverTime', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/irr-overtime-graph/'+ values.organization, {
            params: {
                category: values.category,
                entity: values.entity,
                gain: values.gain,
                quarter_dropdown: values.quarter_dropdown
            },
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getExposureByCategory = createAsyncThunk('PORTFOLIO/getExposureByCategory', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/exposure-category-graph/'+ values.organization, {
            params: {
                category: values.category,
                entity: values.entity,
                overtime: values.overtime,
                quarter_dropdown: values.quarter_dropdown
            },
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getIrreByCategory = createAsyncThunk('PORTFOLIO/getIrreByCategory', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/irr-category-graph/'+ values.organization, {
            params: {
                category: values.category,
                entity: values.entity,
                overtime: values.overtime,
                ytd: values.ytd,
                gain: values.gain,
                quarter_dropdown: values.quarter_dropdown
            },
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const portfolioSlice = createSlice({

    name: 'portfolio',
    initialState: {
        dates: [],
        categories: [],
        entities: [],
        total_assets: {},
        investment_assets: {},
        ytd_irr: {},
        ytd_irr_menu_options:{
            menuText: 'View gain/loss',
            selected_item: 'ytd-irr'
        },
        all_time_irr: {},
        all_time_irr_menu_options: {
            menuText: 'View gain/loss',
            selected_item: 'all-time-irr'
        },
        investmentAssetsValueByCategoryViewGraph: {
            graphType: 'bar',
            menuText: 'View line chart',
        },
        filterDate: '',
        filterCategory: '',
        filterEntity: '',
        investmentAssetsValueByCategoryViewGraphSeries: '',
        investmentAssetsValueByCategoryViewGraphCategories: '',
        irrOverTimeGraph: {
            graphType: 'area',
            menuText: 'View gain/loss',
            selectedFilter: 'view-irr-over-time',
            cardTitle: 'IRR Over Time',
        },
        irrOverTimeGraphSeries: '',
        irrOverTimeGraphCategories: '',
        exposureByCategoryGraphSeries: '',
        exposureByCategoryGraphCategories: '',
        exposureByCategoryGraphLabels: '',
        exposureByCategoryGraphColors: '',
        exposureByCategoryGraph: {
            graphType: 'donut',
            menuText: 'View over time (% of 100)',
            selectedFilter: 'view-donut-chart',
        },
        irrByCategoryGraphSeries: '',
        irrByCategoryGraphCategories: '',
        irrByCategoryGraph: {
            graphType: 'bar',
            menuText: 'View gain/loss',
            selectedFilter: 'view-irr-category',
            cardTitle: 'IRR By Category',
        },
        currentRequestId: '',
        error: "",
    },
    reducers: {
        setInvestmentAssetsValueByCategoryViewGraphType: (state, {payload}) => {
            state.investmentAssetsValueByCategoryViewGraph = { graphType: payload.graphType, menuText: payload.menuText }
        },
        setIrrOverTimeViewGraphType: (state, {payload}) => {
            state.irrOverTimeGraph = { graphType: payload.graphType, menuText: payload.menuText, cardTitle: payload.cardTitle, selectedFilter: payload.selectedFilter }
        },
        setExposureByCategoryViewGraph: (state, {payload}) => {
            state.exposureByCategoryGraph = { graphType: payload.graphType, menuText: payload.menuText, selectedFilter: payload.selectedFilter }
        },
        resetExposureByCategoryViewGraph: (state) => {
            state.exposureByCategoryGraphSeries = ''
            state.exposureByCategoryGraphCategories = ''
            state.exposureByCategoryGraphLabels = ''
            state.exposureByCategoryGraphColors = ''
        },
        setIrrByCategoryViewGraph: (state, {payload}) => {
            state.irrByCategoryGraph = { graphType: payload.graphType, menuText: payload.menuText, cardTitle: payload.cardTitle, selectedFilter: payload.selectedFilter }
        },
        resetIrrByCategoryViewGraph: (state) => {
            state.irrByCategoryGraphSeries = ''
            state.irrByCategoryGraphCategories = ''
        },
        setFilterDateOfInvestmentAssetsValueByCategoryViewGraphType: (state, {payload}) => {
            state.filterDate = payload.filterDate
        },
        setFilterCategoryOfInvestmentAssetsValueByCategoryViewGraphType: (state, {payload}) => {
            state.filterCategory = payload.filterCategory
        },
        setFilterEntityOfInvestmentAssetsValueByCategoryViewGraphType: (state, {payload}) => {
            state.filterEntity = payload.filterEntity
        },
        setYtdIrrMenuOptions: (state, {payload}) => {
            state.ytd_irr_menu_options = { menuText: payload.menuText, selected_item: payload.selected_item }
        },
        setAllTimeIrrMenuOptions: (state, {payload}) => {
            state.all_time_irr_menu_options = { menuText: payload.menuText, selected_item: payload.selected_item }
        }
    },
    extraReducers(builder) {
        //getPortfolioInitialData response handler
        builder
        .addCase(getPortfolioInitialData.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    let newObject = {id: '', name: 'All', organization: 9};
                    payload.data.entities.unshift(newObject);
                    state.categories = payload.data.categories.map((option, index) => ({ value: index + 1, label: option }))
                    state.entities = payload.data.entities.map(option => ({ value: option.id, label: option.name, organization: option.organization }))
                    state.dates = Object.values(payload.data.date_range).map(option => ({ value: option, label: option}))




                }
            }
        })
        .addCase(getPortfolioInitialData.pending, (state, { meta }) => {
        state.currentRequestId = meta

        })
        .addCase(getPortfolioInitialData.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })

        //getTotalAssets response handler
        .addCase(getTotalAssets.fulfilled, (state, { meta, payload }) => {
            // if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                   let total_assets = {
                        increase: payload.data.increase,
                        percentage: payload.data.percentage,
                        value: payload.data.value,
                    }
                    
                    state.total_assets = total_assets
                }
            // }
        })
        .addCase(getTotalAssets.pending, (state, { meta }) => {
        state.currentRequestId = meta

        })
        .addCase(getTotalAssets.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //getInvestmentAssets response handler
        .addCase(getInvestmentAssets.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                   let investment_assets = {
                        increase: payload.data.increase,
                        percentage: payload.data.percentage,
                        value: payload.data.value,
                    }

                    state.investment_assets = investment_assets
                }
            }
        })
        .addCase(getInvestmentAssets.pending, (state, { meta }) => {
        state.currentRequestId = meta

        })
        .addCase(getInvestmentAssets.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //getYTDIRR response handler
        .addCase(getYTDIRR.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    let ytd_irr = {
                        increase: payload.data.increase,
                        percentage: payload.data.percentage,
                        value: payload.data.value,
                    }

                    state.ytd_irr = ytd_irr
                }
            }
        })
        .addCase(getYTDIRR.pending, (state, { meta }) => {
        state.currentRequestId = meta

        })
        .addCase(getYTDIRR.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //getAllTimeIRR response handler
        .addCase(getAllTimeIRR.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    let all_time_irr = {
                        increase: payload.data.increase,
                        percentage: payload.data.percentage,
                        value: payload.data.value,
                    }

                    state.all_time_irr = all_time_irr
                }
            }
        })
        .addCase(getAllTimeIRR.pending, (state, { meta }) => {
        state.currentRequestId = meta

        })
        .addCase(getAllTimeIRR.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //getInvestedAssetsValueByCategory response handler
        .addCase(getInvestedAssetsValueByCategory.fulfilled, (state, { meta, payload }) => {
            if(payload.status === 200) {
                state.investmentAssetsValueByCategoryViewGraphSeries = payload.data.series
                state.investmentAssetsValueByCategoryViewGraphCategories = payload.data.categories
            }
        })
        .addCase(getInvestedAssetsValueByCategory.pending, (state, { meta }) => {
        state.currentRequestId = meta

        })
        .addCase(getInvestedAssetsValueByCategory.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //getIrrOverTime response handler
        .addCase(getIrrOverTime.fulfilled, (state, { meta, payload }) => {
            if(payload.status === 200) {
                state.irrOverTimeGraphSeries = payload.data.series
                state.irrOverTimeGraphCategories = payload.data.categories
            }
        })
        .addCase(getIrrOverTime.pending, (state, { meta }) => {
        state.currentRequestId = meta

        })
        .addCase(getIrrOverTime.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //getExposureByCategory response handler
        .addCase(getExposureByCategory.fulfilled, (state, { meta, payload }) => {
            if(payload.status === 200) {
                state.exposureByCategoryGraphColors = payload.data.colors
                state.exposureByCategoryGraphSeries = payload.data.series
                state.exposureByCategoryGraphLabels = payload.data.labels
                state.exposureByCategoryGraphCategories = payload.data.categories
            }
        })
        .addCase(getExposureByCategory.pending, (state, { meta }) => {
        state.currentRequestId = meta

        })
        .addCase(getExposureByCategory.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //getIrreByCategory response handler
        .addCase(getIrreByCategory.fulfilled, (state, { meta, payload }) => {
            if(payload.status === 200) {
                state.irrByCategoryGraphSeries = payload.data.series
                state.irrByCategoryGraphCategories = payload.data.categories
            }
        })
        .addCase(getIrreByCategory.pending, (state, { meta }) => {
        state.currentRequestId = meta

        })
        .addCase(getIrreByCategory.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
    }
})

export const { setInvestmentAssetsValueByCategoryViewGraphType, setFilterDateOfInvestmentAssetsValueByCategoryViewGraphType, setFilterCategoryOfInvestmentAssetsValueByCategoryViewGraphType, setFilterEntityOfInvestmentAssetsValueByCategoryViewGraphType, setIrrOverTimeViewGraphType, setExposureByCategoryViewGraph, resetExposureByCategoryViewGraph, setIrrByCategoryViewGraph, resetIrrByCategoryViewGraph, setYtdIrrMenuOptions, setAllTimeIrrMenuOptions } = portfolioSlice.actions
export default portfolioSlice.reducer