import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DatePicker from '../../../../components/commonComponents/DatePicker'
import SimpleSelectInput from '../../../../components/commonComponents/SimpleSelectInput'
import lodash from 'lodash'

import { setAssetSummaryEventSplitsEntityFilter, setAssetSummaryEventSplitsDateFilter } from '../../../../redux/slices/assets/assetSummarySlice'

function exportTableDataToCSV(asset_id, assetsSelectorOptions, eventsSummaryData, eventsSummaryTableColumns) {

    let select_asset = lodash.find(assetsSelectorOptions, function(option) { return option.value === asset_id })
    select_asset = select_asset.label
    let tableData = eventsSummaryData
    const formattedDate = new Date().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-');
    function replaceKeys(data, mapping) {
      return data.map(item => {
        const updatedItem = {};
        mapping.forEach(entry => {
          const { col_name, col_key } = entry;
          updatedItem[col_name] = item[col_key];
        });
        return updatedItem;
      });
    }
    const updatedData = replaceKeys(tableData, eventsSummaryTableColumns)

    // Function to format the data
    function formatData(data) {

      return data.map(item => {
        const formattedItem = { ...item };
        formattedItem['Share Price'] = "$" + item['Share Price'].toFixed(2).toString();
        formattedItem.Amount = "$" + formattedItem.Amount.toFixed(2).toString();
        return formattedItem;
      });
    }
    // Format the data
    tableData = formatData(updatedData);

    // Ensure that `tableData` is an array of objects
      if (!Array.isArray(tableData) || tableData.length === 0 || typeof tableData[0] !== 'object') {
        console.error('Invalid data format for CSV export');
        return;
      }

      // Create a CSV header using the keys of the first object
      const header = Object.keys(tableData[0]);

      // Create an array to hold the CSV data
      const csvData = [header];

      // Add data rows to the CSV
      for (const row of tableData) {
        const rowData = header.map(key => row[key]);
        csvData.push(rowData);
      }

      // Convert the CSV data to a CSV string
      const csvContent = csvData.map(row => row.join(',')).join('\n');

      // Create a Blob with the CSV content
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', select_asset + '-' + formattedDate + '.csv');

      // Trigger the download
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
}

function EventSplitsActionButtions(props) {
    
    const dispatch = useDispatch()

    const user = useSelector(state => state.auth.user)
    const eventSplitsEntities = useSelector(state => state.assetSummary.eventSplitsEntities)
    const assetSummaryEventSplitsEntityFilter = useSelector(state => state.assetSummary.assetSummaryEventSplitsEntityFilter)
    const assetsSelectorOptions = useSelector(state => state.assetSummary.assetsSelectorOptions)
    const eventSplitsData = useSelector(state => state.assetSummary.eventSplitsData)
    const eventSplitsTableColumns = useSelector(state => state.assetSummary.eventSplitsTableColumns)
    
    const getDataWithInRange = (fromDate, endDate ) => {
        dispatch(setAssetSummaryEventSplitsDateFilter({ assetSummaryEventSplitsStartDateFilter: fromDate, assetSummaryEventSplitsEndDateFilter: endDate }))
    }

    const handleChangeEntity = (selectedOption) => {
        dispatch(setAssetSummaryEventSplitsEntityFilter({ assetSummaryEventSplitsEntityFilter: selectedOption.value }))
    }

    return (
        <div className='actions'>
            <DatePicker singlePicker={ false } getDataWithInRange={getDataWithInRange} />
            <button className="filters-button" onClick={() => exportTableDataToCSV(props.asset_id, assetsSelectorOptions, eventSplitsData, eventSplitsTableColumns)}>Export</button>
            <SimpleSelectInput showLabel="true" placeholder="Entity" className="asset-summary-event-splits-filter-entity select-filter" selectedBackgroundColor="black" selectedColor="white" selectedValue={ lodash.find(eventSplitsEntities, function(entity) { return entity.value === assetSummaryEventSplitsEntityFilter }) } handleOnChange={ handleChangeEntity } options={ eventSplitsEntities } isFormik={false} name="asset-summary-event-splits" />
        </div>
    )
}

export default EventSplitsActionButtions