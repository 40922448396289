import React from 'react'

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Loader(props) {
  return (
    <Box
        sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: props.width ? props.width : '100%',
        height: props.height,
        }}
    >
        <CircularProgress color='inherit' />
    </Box>
  )
}

export default Loader