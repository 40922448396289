import { createSlice , createAsyncThunk } from '@reduxjs/toolkit'
import Http from '../../../util/Http'
import { getLoggedUserAccessToken } from '../../../util/commonFunctions'
import lodash  from 'lodash'


export const getOrganizationList = createAsyncThunk('ORGANIZATION/getOrganizationList', async ( values    , { rejectWithValue }) => {
    try {
        const response = await Http.get('/organizations/', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const createNewOrganization = createAsyncThunk('ORGANIZATION/createNewOrganization', async ( values   , { rejectWithValue }) => {
    try {
        const response = await Http.post('/organizations/', values, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
        return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getOrganizationDataForEdit = createAsyncThunk('ORGANIZATION/getOrganizationDataForEdit', async ( id , { rejectWithValue }) => {
    try {
        const response = await Http.get('/organizations/' + id + '/', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
        return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const updateOrganization = createAsyncThunk('ORGANIZATION/updateOrganization', async (  { values, id }   , { rejectWithValue }) => {
    try {
        const response = await Http.put('/organizations/'+ id + '/' , values, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
        return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const deleteOrganization = createAsyncThunk('ORGANIZATION/deleteOrganization', async ( { id }   , { rejectWithValue }) => {
    try {
        const response = await Http.delete('/organizations/'+ id + '/', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
        return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const organizationlice = createSlice({

    name: 'organization',
    initialState: {
        organizations: [],
        editOrganization: '',
        organizations_table_columns: '',
        addOrganizationModalShown: false,
        editOrganizationModalShown: false,
        currentRequestId: '',
        error: "",
    },
    reducers: {
        showAddOrganizationModal: state => {
            state.addOrganizationModalShown = true
        },
        hideAddOrganizationModal: state => {
            state.addOrganizationModalShown = false
        },
        showEditOrganizationModal: state => {
            state.editOrganizationModalShown = true
        },
        hideEditOrganizationModal: state => {
            state.editOrganizationModalShown = false
            state.editOrganization = ''
        },
    },
    extraReducers(builder) {
        //login response handler
        builder
          .addCase(getOrganizationList.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    state.organizations = payload.data.data
                    state.organizations_table_columns = payload.data.table_cols
                }
            }
          })
          .addCase(getOrganizationList.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(getOrganizationList.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })

        // createNewOrganization response handler
        .addCase(createNewOrganization.fulfilled, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.organizations.push(payload.data)
            }
          })
          .addCase(createNewOrganization.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(createNewOrganization.rejected, (state, {meta, payload, error}) => {
              if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })

        //getOrganizationDataForEdit response handler
        .addCase(getOrganizationDataForEdit.fulfilled, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    state.editOrganization = payload.data
                }
            }
          })
          .addCase(getOrganizationDataForEdit.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(getOrganizationDataForEdit.rejected, (state, {meta, payload, error}) => {
              if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })

        //updateOrganization response handler
        .addCase(updateOrganization.fulfilled, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    const replaceIndex = lodash.findIndex(state.organizations, function(organization) { return organization.id === payload.data.id });
                    state.organizations = state.organizations.map((object, index) =>
                        index === replaceIndex ? payload.data : object
                    )
                }
            }
          })
          .addCase(updateOrganization.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(updateOrganization.rejected, (state, {meta, payload, error}) => {
              if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //deleteOrganization response handler
        .addCase(deleteOrganization.fulfilled, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    lodash.remove(state.organizations, function(organization) { return organization.id === payload.data.id });
                }
            }
          })
          .addCase(deleteOrganization.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(deleteOrganization.rejected, (state, {meta, payload, error}) => {
              if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })

    }
})

export const { showAddOrganizationModal, hideAddOrganizationModal, showEditOrganizationModal, hideEditOrganizationModal } = organizationlice.actions
export default organizationlice.reducer