import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AssetSummaryBreadCrumbs from './AssetSummaryBreadCrumbs';
import AssetSummaryHeaderContent from './AssetSummaryHeaderContent';
import AssetSummaryTable from './AssetSummaryTable';
import { getOrganizationIdOFLoggedUser } from '../../../../util/commonFunctions';

import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';
import { getAssetSummary } from '../../../../redux/slices/assets/assetSummarySlice';

function AssetSummary(props) {

  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)
  const assets = useSelector(state => state.assetSummary.assets)
  const summary_data = useSelector(state => state.assetSummary.summary_data)
  const assets_summary_table_columns = useSelector(state => state.assetSummary.assets_summary_table_columns)

  useGetAssetsList(dispatch, user)

  return (
    <>
      <div className='header-section'>
        <div className='asset-summary-page-container'>
        <div className='asset-summary-page-header'>
          <AssetSummaryBreadCrumbs />
          <AssetSummaryHeaderContent />
        </div>
        </div>
      </div>
      { assets && assets_summary_table_columns && assets_summary_table_columns.length > 0 && <AssetSummaryTable setSelectedAssetIdToViewAssetDetail={ props.setSelectedAssetIdToViewAssetDetail }
                                                                                                                setSelectedAssetInvestmentTypeDetail={ props.setSelectedAssetInvestmentTypeDetail }
                                                                                                                tableData={ assets } tableHeader={ assets_summary_table_columns } summaryData={summary_data} /> }
    </>
  )
}

async function getAssetsSummary(dispatch, user, retry = 0) {
  const { payload } = await dispatch(getAssetSummary({ organization: getOrganizationIdOFLoggedUser(user) }))

  if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
    await dispatch(getNewAccessToken());

    return getAssetsSummary(dispatch, user, retry + 1);
  }

  return payload
}

function useGetAssetsList(dispatch, user) {
  const isMountRef = useRef(true);
  useEffect(() => {
    if(isMountRef.current) {
      const fetchUsers = async () => {
        const usersData = await getAssetsSummary(dispatch, user);
      };
  
      fetchUsers();
      isMountRef.current = false;
    }
  }, []);
}

export default AssetSummary;