import React from 'react'

function AddUserIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clipPath="url(#clip0_978_8420)">
            <path d="M15.8333 6.66699V1.66699M13.3333 4.16699H18.3333M18.3333 10.0003V14.3337C18.3333 15.7338 18.3333 16.4339 18.0609 16.9686C17.8212 17.439 17.4387 17.8215 16.9683 18.0612C16.4335 18.3337 15.7335 18.3337 14.3333 18.3337H5.66667C4.26654 18.3337 3.56647 18.3337 3.03169 18.0612C2.56129 17.8215 2.17884 17.439 1.93915 16.9686C1.66667 16.4339 1.66667 15.7338 1.66667 14.3337V5.66699C1.66667 4.26686 1.66667 3.5668 1.93915 3.03202C2.17884 2.56161 2.56129 2.17916 3.03169 1.93948C3.56647 1.66699 4.26654 1.66699 5.66667 1.66699H10M1.78812 16.6056C2.17907 15.1992 3.469 14.167 5 14.167H10.8333C11.6078 14.167 11.995 14.167 12.317 14.231C13.6393 14.4941 14.6729 15.5277 14.936 16.85C15 17.172 15 17.5592 15 18.3337M11.6667 7.91699C11.6667 9.75794 10.1743 11.2503 8.33333 11.2503C6.49239 11.2503 5 9.75794 5 7.91699C5 6.07604 6.49239 4.58366 8.33333 4.58366C10.1743 4.58366 11.6667 6.07604 11.6667 7.91699Z" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_978_8420">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
        </defs>
    </svg>
  )
}

export default AddUserIcon