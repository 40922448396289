import React from 'react'

function SelectorTickIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M16.6667 5L7.50004 14.1667L3.33337 10" stroke="#43BBFF" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default SelectorTickIcon