import React from 'react'

function MailIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M1.66667 5.83301L8.47077 10.5959C9.02175 10.9816 9.29724 11.1744 9.59689 11.2491C9.86158 11.3151 10.1384 11.3151 10.4031 11.2491C10.7028 11.1744 10.9783 10.9816 11.5292 10.5959L18.3333 5.83301M5.66667 16.6663H14.3333C15.7335 16.6663 16.4335 16.6663 16.9683 16.3939C17.4387 16.1542 17.8212 15.7717 18.0609 15.3013C18.3333 14.7665 18.3333 14.0665 18.3333 12.6663V7.33301C18.3333 5.93288 18.3333 5.23281 18.0609 4.69803C17.8212 4.22763 17.4387 3.84517 16.9683 3.60549C16.4335 3.33301 15.7335 3.33301 14.3333 3.33301H5.66667C4.26654 3.33301 3.56647 3.33301 3.0317 3.60549C2.56129 3.84517 2.17884 4.22763 1.93916 4.69803C1.66667 5.23281 1.66667 5.93288 1.66667 7.33301V12.6663C1.66667 14.0665 1.66667 14.7665 1.93916 15.3013C2.17884 15.7717 2.56129 16.1542 3.0317 16.3939C3.56647 16.6663 4.26654 16.6663 5.66667 16.6663Z" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default MailIcon