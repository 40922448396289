import React, { useState, useEffect, useLayoutEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import AuthLayout from "./components/layout/AuthLayout";
import Http from './util/Http';

import { getLoggedUserInitialRoute, isSameRole } from "./util/commonFunctions";

import { logoutUser, clearState } from './redux/slices/auth/authSlice';

//redux
import { fetchLoggedUser } from './redux/slices/auth/authSlice';

function App(props) {

  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const accessToken = useSelector((state) => state.auth.accessToken);
  const user = useSelector((state) => state.auth.user);

  Http.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if(error.response.status === 401 && error.response.config.url === '/api/token/refresh/') {
      dispatch(logoutUser())
      .then(({payload}) => {
        dispatch(clearState())
        return navigate("/login", { replace: true });
      })
    }
  
    return Promise.reject(error)
  })

  useGetLoggedUserDetails(dispatch, navigate, user)

  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      setIsLoggedIn(false);
      return navigate("/login", { replace: true });
    } else if (localStorage.getItem('access_token') && user && user.hasOwnProperty("id")) {
      if(location.pathname === '/') {
        return navigate(getLoggedUserInitialRoute(user),  { replace: true });
      }
      setIsLoggedIn(true);
    }
  }, [accessToken, location.pathname]);

  return (
    <React.Fragment>{isLoggedIn === true && <AuthLayout />}</React.Fragment>
  );
}

function useGetLoggedUserDetails(dispatch, navigate, user) {
  useLayoutEffect(() => {
    if(localStorage.getItem('user')) {
      dispatch(fetchLoggedUser())
      .then(({ payload }) => {
        if(payload.status === 200) {
          const storedUser = JSON.parse(localStorage.getItem('user'));
          const currenUser = payload.data.user; 

          if(!isSameRole(storedUser, currenUser)) {
            dispatch(logoutUser())
            .then(({payload}) => {
              if(payload && payload.status === 200) {
                return navigate("/login", { replace: true });
              }
              if(payload && payload.response && payload.response.status === 400) {
                dispatch(clearState())
                return navigate("/login", { replace: true });
              }
            })
          }
        }
      })
    }
  }, []);
}

export default App;