import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Typography } from "@mui/material";
import ModalCloseIcon from '../icons/ModalCloseIcon';

import { hideAlertModal } from '../../redux/slices/alertSlice';

function AlertModal() {

    const dispatch = useDispatch();

    const alertModalShown = useSelector(state => state.alert.alertModalShown)
    const alertTitle = useSelector(state => state.alert.alertTitle)
    const alertMessage = useSelector(state => state.alert.alertMessage)

    const closeAlertModal = () => {
        dispatch(hideAlertModal())
    }

    return (
        <Modal
            open={alertModalShown}
            onClose={ closeAlertModal }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="alert-modal-view"
        >
            <div className="alert-modal">
                <div className="alert-modal-header">
                    <div className="alert-modal-header-content">
                        <div className="alert-modal-header-icons-view">
                            <div className="alert-modal-header-close-button">
                                <ModalCloseIcon closeModal={ closeAlertModal } />
                            </div>
                        </div>
                        <div className="alert-modal-header-text">
                            <Typography className="alert-modal-header-title-text">{ alertTitle }</Typography>
                            <Typography className="alert-modal-header-info-text">{ alertMessage }</Typography>
                        </div>
                    </div>
                </div>
                <div className="alert-modal-content">
                    <div className="alert-form">
                        <div className="alert-modal-footer">
                            <div className="alert-modal-footer-content">
                                <div className="alert-modal-footer-buttons">
                                    <button type="button" onClick={ closeAlertModal } className="cancel-user-button">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AlertModal;