import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: "#FFFFFF",
    color: "#344054",
    hoverBg: "#000000",
    activeBg: "#000000",
    iconStroke: "#667085",
  },
  topbar: {
    bg: "#fff",
    color: "#000"
  },
  mainBg: colors.grey["100"]
};

export default colorConfigs;