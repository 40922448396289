import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import { isRoleAllowedToAccessGiverRoute } from "./commonFunctions";

export default function ProtectedRoute(props) {

    const navigate = useNavigate();

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const accessToken = useSelector(state => state.auth.accessToken)
    const user = useSelector(state => state.auth.user)

    useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            setIsLoggedIn(false);
            return navigate('/login',  { replace: true });
        }
        
        setIsLoggedIn(true);
        
    }, [accessToken]);
    
    return (
        <React.Fragment>
            {
                isLoggedIn && isRoleAllowedToAccessGiverRoute(props.allowedRoles, user) ? props.children : null
            }
        </React.Fragment>
    );
}