import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import FileIcon from './icons/FileIcon';


function IconToolTip(props) {

  const DisbaledFileView = () => {
    return (
      <svg className='disabled-file me-4' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M11.6667 9.16699H6.66667M8.33334 12.5003H6.66667M13.3333 5.83366H6.66667M16.6667 5.66699V14.3337C16.6667 15.7338 16.6667 16.4339 16.3942 16.9686C16.1545 17.439 15.7721 17.8215 15.3016 18.0612C14.7669 18.3337 14.0668 18.3337 12.6667 18.3337H7.33334C5.9332 18.3337 5.23314 18.3337 4.69836 18.0612C4.22795 17.8215 3.8455 17.439 3.60582 16.9686C3.33334 16.4339 3.33334 15.7338 3.33334 14.3337V5.66699C3.33334 4.26686 3.33334 3.5668 3.60582 3.03202C3.8455 2.56161 4.22795 2.17916 4.69836 1.93948C5.23314 1.66699 5.9332 1.66699 7.33334 1.66699H12.6667C14.0668 1.66699 14.7669 1.66699 15.3016 1.93948C15.7721 2.17916 16.1545 2.56161 16.3942 3.03202C16.6667 3.5668 16.6667 4.26686 16.6667 5.66699Z" stroke="#475467" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  }

  return (
    props.viewable ? 
      <Tooltip title={props.toolTipData}>
          <div>
              <FileIcon disable={ props.disable } />
          </div>
      </Tooltip>
      :
    <DisbaledFileView />
  )
}

export default IconToolTip