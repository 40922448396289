import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import PortfolioStat from './PortfolioStat'
import Loader from '../../../../components/commonComponents/Loader';

import { getOrganizationIdOFLoggedUser } from '../../../../util/commonFunctions';
import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';
import { getTotalAssets } from '../../../../redux/slices/portfolio/portfolioSlice';

function TotalAssets(props) {

    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user)
    const total_assets = useSelector(state => state.portfolio.total_assets)
    const categories = useSelector(state => state.portfolio.categories)
    const entities = useSelector(state => state.portfolio.entities)
    const filterEntity = useSelector(state => state.portfolio.filterEntity)
    const filterDate = useSelector(state => state.portfolio.filterDate)

    const [totalAssetsLoading, setTotalAssetsLoading] = useState(true)


    useGetPortfolioTotalAssets(dispatch, user, categories, entities, filterEntity, filterDate, setTotalAssetsLoading);

    return (
        totalAssetsLoading ?
        <Loader width="25%"/>
        :
        <div className='metric-item'>
            <div className='header'>
                <span className='title'>Total Assets</span>
            </div>
            { total_assets && total_assets.hasOwnProperty('value') && <PortfolioStat show={ true } irrType={props.irrType} stat={total_assets} /> }
        </div>
    )
}

function useGetPortfolioTotalAssets(dispatch, user, categories, entities, filterEntity, filterDate, setTotalAssetsLoading) {
    
    useEffect(() => {
        if(categories && entities && categories.length && entities.length ) {
            setTotalAssetsLoading(true)
            fetchData(dispatch, user)
        }
    }, [dispatch, user, categories, entities, filterEntity, filterDate]);

    async function fetchData(dispatch, user, retry=0) {
        const { payload } = await dispatch(getTotalAssets({ organization: getOrganizationIdOFLoggedUser(user) ,
            entity: filterEntity,
            quarter_dropdown: filterDate
        }))
  
        if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
            dispatch(getNewAccessToken());
            fetchData(dispatch, user, retry + 1);
        }
        else if(payload && payload.status === 200) {
            setTotalAssetsLoading(false)
        }
    }
}

export default TotalAssets