import React from 'react'

function SelectSponserIcon(props) {
  return (
    <svg className={'person-icon ' + `${props.isUserSelected && 'selected-person-icon'}`} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M16.6667 17.5C16.6667 16.337 16.6667 15.7555 16.5231 15.2824C16.2 14.217 15.3663 13.3834 14.3009 13.0602C13.8278 12.9167 13.2463 12.9167 12.0833 12.9167H7.91667C6.75369 12.9167 6.17221 12.9167 5.69904 13.0602C4.63371 13.3834 3.80003 14.217 3.47686 15.2824C3.33333 15.7555 3.33333 16.337 3.33333 17.5M13.75 6.25C13.75 8.32107 12.0711 10 9.99999 10C7.92893 10 6.24999 8.32107 6.24999 6.25C6.24999 4.17893 7.92893 2.5 9.99999 2.5C12.0711 2.5 13.75 4.17893 13.75 6.25Z" stroke={props.stroke} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default SelectSponserIcon