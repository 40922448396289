import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PortfolioStat from './PortfolioStat'
import Loader from '../../../../components/commonComponents/Loader';

import { getOrganizationIdOFLoggedUser } from '../../../../util/commonFunctions';
import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';
import { getInvestmentAssets } from '../../../../redux/slices/portfolio/portfolioSlice';

function InvestmentAssets(props) {

    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user)
    const total_assets = useSelector(state => state.portfolio.total_assets)
    const investment_assets = useSelector(state => state.portfolio.investment_assets)
    const filterEntity = useSelector(state => state.portfolio.filterEntity)
    const filterDate = useSelector(state => state.portfolio.filterDate)

    const [investmentAssetsLoading, setInvestmentAssetsLoading] = useState(true)

    useGetPortfolioInvestmentAssets(dispatch, user, total_assets, filterEntity, filterDate, setInvestmentAssetsLoading);

    return (
        investmentAssetsLoading ? 
        <Loader width="25%" />
        :
        <div className='metric-item'>
            <div className='header'>
                <span className='title'>Investment Assets</span>
            </div>
            { investment_assets && investment_assets.hasOwnProperty('value') && <PortfolioStat show={ true } irrType={props.irrType} stat={investment_assets} /> }
        </div>
    )
}
  
function useGetPortfolioInvestmentAssets(dispatch, user, total_assets, filterEntity, filterDate, setInvestmentAssetsLoading) {

    useEffect(() => {
        if (total_assets && total_assets.hasOwnProperty('increase') && total_assets.hasOwnProperty('percentage') && total_assets.hasOwnProperty('value')) {
            setInvestmentAssetsLoading(true)
            fetchData(dispatch, user)
        }
    }, [dispatch, user, total_assets, filterEntity, filterDate]);

    async function fetchData(dispatch, user, retry=0) {
        const { payload } = await dispatch(getInvestmentAssets({ organization: getOrganizationIdOFLoggedUser(user),
            entity: filterEntity,
            quarter_dropdown: filterDate
        }))
  
        if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
            dispatch(getNewAccessToken());
            fetchData(dispatch, user, retry + 1);
        }
        else if(payload && payload.status === 200) {
            setInvestmentAssetsLoading(false)
        }

    }
}

export default InvestmentAssets