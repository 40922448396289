import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { setAccountSettingTabState } from '../../redux/slices/account/accountSettingSlice';
import Logout from '../../components/icons/Logout'
import { clearState } from '../../redux/slices/auth/authSlice';
import { getNewAccessToken } from '../../redux/slices/auth/authSlice';

import { logoutUser } from '../../redux/slices/auth/authSlice';

function AccountSettingHeader() {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const tabState = useSelector(state => state.accountSetting.tabState)

  const toggleToProfileTab = (tabState) => {
    dispatch(setAccountSettingTabState({ tabState: tabState}))
  }

  const toggleToChangePasswordTab = (tabState) => {
    dispatch(setAccountSettingTabState({ tabState: tabState}))
  }

  const logout = (retry = 0) => {
    dispatch(logoutUser())
    .then(({payload}) => {
      if(payload && !payload.response && payload.status === 200) {
        return navigate("/login", { replace: true });
      }
      else if(payload && payload.response && payload.response.status === 400) {
        dispatch(clearState())
        return navigate("/login", { replace: true });
      }
      else if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
        dispatch(getNewAccessToken())
        .then((res) => {
            if(res.payload && res.payload.data && res.payload.data.success) {
                return logout(retry + 1)
            }
        })
      }
    })
  }

  return (
    <div className="account-setting-header-container">
        <div className="button-group">
            <button className={"profile-tab-button " + `${tabState && tabState === 'profile' ? 'profile-tab-active' : '' }`} onClick={ () => toggleToProfileTab('profile') }>Profile</button>
            <button className={"change-password-tab-button " + `${tabState && tabState === 'change-password' ? 'change-password-tab-active' : '' }`} onClick={ () => toggleToChangePasswordTab('change-password') }>Change Password</button>
        </div>

        <div className="action-buttons">
            <button onClick={ logout } className="logout-button"><Logout />Logout</button>
        </div>
    </div>
  )
}

export default AccountSettingHeader