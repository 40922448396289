import axios from "axios"
// Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.

const Http = axios.create({
    baseURL: process.env.REACT_APP_DJANGO_API_URL,
    timeout: 10000,
})

export default Http;