import React from 'react'

function AddAssetProgressInActiveIcon() {
  return (
    <svg className='inactive-progress-icon' xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <circle cx="12.4767" cy="12" r="4" fill="white"/>
    </svg>
  )
}

export default AddAssetProgressInActiveIcon