import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DatePicker from '../../../components/commonComponents/DatePicker';
import UserFilterIcon from '../../../components/icons/FilterButtonIcon';
import { isAddAssetAllowedToLoggedUser, getOrganizationIdOFLoggedUser } from '../../../util/commonFunctions';
import { getOrganizationEvents } from '../../../redux/slices/eventdatabase/eventDataBaseSlice';

import { showAddEventdataBaseModal } from '../../../redux/slices/eventdatabase/eventDataBaseSlice';
import { getDataBaseEventInitialData } from '../../../redux/slices/eventdatabase/eventDataBaseSlice';
import lodash from "lodash";

function exportTableDataToCSV(eventsSummaryData, eventsSummaryTableColumns) {

    let tableData = eventsSummaryData
    const formattedDate = new Date().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-');
    function replaceKeys(data, mapping) {
      return data.map(item => {
        const updatedItem = {};
        mapping.forEach(entry => {
          const { col_name, col_key } = entry;
          updatedItem[col_name] = item[col_key];
        });
        return updatedItem;
      });
    }
    const updatedData = replaceKeys(tableData, eventsSummaryTableColumns)

    // Function to format the data
    function formatData(data) {

      return data.map(item => {
        const formattedItem = { ...item };
        formattedItem['Share Price'] = "$" + item['Share Price'].toFixed(2).toString();
        formattedItem.Amount = "$" + formattedItem.Amount.toFixed(2).toString();
        return formattedItem;
      });
    }
    // Format the data
    tableData = formatData(updatedData);

    // Ensure that `tableData` is an array of objects
      if (!Array.isArray(tableData) || tableData.length === 0 || typeof tableData[0] !== 'object') {
        console.error('Invalid data format for CSV export');
        return;
      }

      // Create a CSV header using the keys of the first object
      const header = Object.keys(tableData[0]);

      // Create an array to hold the CSV data
      const csvData = [header];

      // Add data rows to the CSV
      for (const row of tableData) {
        const rowData = header.map(key => row[key]);
        csvData.push(rowData);
      }

      // Convert the CSV data to a CSV string
      const csvContent = csvData.map(row => row.join(',')).join('\n');

      // Create a Blob with the CSV content
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Events-' + formattedDate + '.csv');

      // Trigger the download
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
}
export default function EventDataBaseHeaderContent(props) {

    const dispatch = useDispatch()

    const user = useSelector(state => state.auth.user)
    const organizationEvents = useSelector(state => state.eventDataBase.organizationEvents)
    const eventsDatabaseTableColumns = useSelector(state => state.eventDataBase.events_database_table_columns)

    const EventIcon = () => {
      return (
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M9.99984 2.49984H13.4998C14.9 2.49984 15.6 2.49984 16.1348 2.77232C16.6052 3.012 16.9877 3.39446 17.2274 3.86486C17.4998 4.39964 17.4998 5.09971 17.4998 6.49984V13.4998C17.4998 14.9 17.4998 15.6 17.2274 16.1348C16.9877 16.6052 16.6052 16.9877 16.1348 17.2274C15.6 17.4998 14.9 17.4998 13.4998 17.4998H6.49984C5.09971 17.4998 4.39964 17.4998 3.86486 17.2274C3.39446 16.9877 3.012 16.6052 2.77232 16.1348C2.49984 15.6 2.49984 14.9 2.49984 13.4998V9.99984M6.6665 10.8332V14.1665M13.3332 9.1665V14.1665M9.99984 5.83317V14.1665M4.1665 6.6665V1.6665M1.6665 4.1665H6.6665" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
      )
    }

    const showEventModal = () => {
        let params = {
          organization: getOrganizationIdOFLoggedUser(user)
        }

        dispatch(getDataBaseEventInitialData(params))
        .then(({payload}) => {
            if(payload && payload.status === 200) {
                dispatch(showAddEventdataBaseModal())
            }
        })
    }

    const getDataWithInRange = (fromDate, endDate ) => {
        dispatch(getOrganizationEvents({ organization: getOrganizationIdOFLoggedUser(user), from_date: fromDate, end_date: endDate }))
    }

  return (
    <div className="event-database-header-content">
      <span className="manage-event-database-text">Events</span>
      <DatePicker singlePicker={ false } getDataWithInRange={getDataWithInRange} />
      <div className="event-database-table-filters">
          <button className="filters-button"><UserFilterIcon />Filters</button>
          <button className="filters-button" onClick={() => exportTableDataToCSV(organizationEvents, eventsDatabaseTableColumns)}>Export</button>
        { isAddAssetAllowedToLoggedUser(user) && <button onClick={ () => showEventModal() } className="add-event-button"><EventIcon />Add Event</button>}
      </div>
    </div>
  )
}