import { configureStore , combineReducers } from '@reduxjs/toolkit'
import authReducer from './slices/auth/authSlice'
import organizationReducer from './slices/organization/organizationSlice';
import userReducer from './slices/user/userSlice';
import alertReducer from './slices/alertSlice';
import accountSettingReducer from './slices/account/accountSettingSlice';
import assetSummaryReducer from './slices/assets/assetSummarySlice';
import eventDataBaseReducer from './slices/eventdatabase/eventDataBaseSlice';
import portfolioReducer from './slices/portfolio/portfolioSlice';

const combinedReducer = combineReducers({
    // Auth Area
    auth: authReducer,
    
    // Organization Area
    organization: organizationReducer,

    // User Area
    user: userReducer,

    // alert Area
    alert: alertReducer,

    // accountSetting Area
    accountSetting: accountSettingReducer,

    // assetSummary Area
    assetSummary: assetSummaryReducer,

    // eventDataBase Area
    eventDataBase: eventDataBaseReducer,

    // portfolio Area
    portfolio: portfolioReducer,
});


const rootReducer = (state, action) => {
    if (action.type === 'USER/logoutUser/fulfilled') { // reset the store states on logout
        localStorage.clear()
        return combinedReducer(undefined, action);
    }

    return combinedReducer(state, action);
};

export default configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
