import React from 'react'

function AddAssetProgressActiveIcon() {
  return (
    <svg className='active-progress-icon' xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <rect x="0.523315" width="24" height="24" rx="12" fill="black"/>
        <circle cx="12.5233" cy="12" r="4" fill="white"/>
    </svg>
  )
}

export default AddAssetProgressActiveIcon