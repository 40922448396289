import React from 'react'

function FileIcon() {
  return (
    <svg className='file-icon me-4' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M11.6667 9.1665H6.66667M8.33334 12.4998H6.66667M13.3333 5.83317H6.66667M16.6667 5.6665V14.3332C16.6667 15.7333 16.6667 16.4334 16.3942 16.9681C16.1545 17.4386 15.7721 17.821 15.3016 18.0607C14.7669 18.3332 14.0668 18.3332 12.6667 18.3332H7.33334C5.9332 18.3332 5.23314 18.3332 4.69836 18.0607C4.22795 17.821 3.8455 17.4386 3.60582 16.9681C3.33334 16.4334 3.33334 15.7333 3.33334 14.3332V5.6665C3.33334 4.26637 3.33334 3.56631 3.60582 3.03153C3.8455 2.56112 4.22795 2.17867 4.69836 1.93899C5.23314 1.6665 5.9332 1.6665 7.33334 1.6665H12.6667C14.0668 1.6665 14.7669 1.6665 15.3016 1.93899C15.7721 2.17867 16.1545 2.56112 16.3942 3.03153C16.6667 3.56631 16.6667 4.26637 16.6667 5.6665Z" stroke="#475467" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default FileIcon