import { createSlice , createAsyncThunk } from '@reduxjs/toolkit'
import Http from '../../../util/Http'
import { getLoggedUserAccessToken } from '../../../util/commonFunctions'
import lodash  from 'lodash'


export const getUserList = createAsyncThunk('USER/getUserList', async ( values    , { rejectWithValue }) => {
    try {
        const response = await Http.get('/users/', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getUserOrganizationANdRoleList = createAsyncThunk('USER_ORGANIZATION/getUserOrganizationANdRoleList', async ( values   , { rejectWithValue }) => {
    try {
        const response = await Http.get('/get-initial-data/', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
        return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const createNewUser = createAsyncThunk('USER/createNewUser', async ( values   , { rejectWithValue }) => {
    try {
        const response = await Http.post('/users/', values, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
        return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getUserDataForEdit = createAsyncThunk('USER/getUserDataForEdit', async ( id , { rejectWithValue }) => {
    try {
        const response = await Http.get('/users/' + id + '/', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
        return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const updateUser = createAsyncThunk('USER/updateUser', async (  { values, id }   , { rejectWithValue }) => {
    try {
        const response = await Http.put('/users/'+ id + '/' , values, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
        return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const deleteUser = createAsyncThunk('USER/deleteUser', async ( { id }   , { rejectWithValue }) => {
    try {
        const response = await Http.delete('/users/'+ id + '/', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
        return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const userSlice = createSlice({

    name: 'user',
    initialState: {
        users: [],
        user_organization_list: [],
        user_role_list: [],
        editUser: '',
        users_table_columns: '',
        addUserModalShown: false,
        editUserModalShown: false,
        currentRequestId: '',
        error: "",
    },
    reducers: {
        showAddUserModal: state => {
            state.addUserModalShown = true
        },
        hideAddUserModal: state => {
            state.addUserModalShown = false
        },
        showEditUserModal: state => {
            state.editUserModalShown = true
        },
        hideEditUserModal: state => {
            state.editUserModalShown = false
            state.editUser = ''
        }
    },
    extraReducers(builder) {
        //login response handler
        builder
          .addCase(getUserList.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    state.users = payload.data.data
                    state.users_table_columns = payload.data.table_cols
                }
            }
          })
          .addCase(getUserList.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(getUserList.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })

        // getUserOrganizationANdRoleList response handler
        .addCase(getUserOrganizationANdRoleList.fulfilled, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    state.user_organization_list = payload.data.organization.map(option => ({ value: option.id, label: option.name }))
                    state.user_role_list = payload.data.role.map(option => ({ value: option.id, label: option.name }))
                }
            }
          })
          .addCase(getUserOrganizationANdRoleList.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(getUserOrganizationANdRoleList.rejected, (state, {meta, payload, error}) => {
              if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })

        // createNewUser response handler
        .addCase(createNewUser.fulfilled, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 201) {
                    state.users.push(payload.data)
                }
            }
          })
          .addCase(createNewUser.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(createNewUser.rejected, (state, {meta, payload, error}) => {
              if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })

        //getUserDataForEdit response handler
        .addCase(getUserDataForEdit.fulfilled, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    state.editUser = payload.data
                }
            }
          })
          .addCase(getUserDataForEdit.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(getUserDataForEdit.rejected, (state, {meta, payload, error}) => {
              if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })

        //updateUser response handler
        .addCase(updateUser.fulfilled, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    const replaceIndex = lodash.findIndex(state.users, function(user) { return user.id === payload.data.id });
                    state.users = state.users.map((object, index) =>
                        index === replaceIndex ? payload.data : object
                    )
                }
            }
          })
          .addCase(updateUser.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(updateUser.rejected, (state, {meta, payload, error}) => {
              if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //deleteUser response handler
        .addCase(deleteUser.fulfilled, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    lodash.remove(state.users, function(organization) { return organization.id === payload.data.id });
                }
            }
          })
          .addCase(deleteUser.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(deleteUser.rejected, (state, {meta, payload, error}) => {
              if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })

    }
})

export const { showAddUserModal, hideAddUserModal, showEditUserModal, hideEditUserModal } = userSlice.actions
export default userSlice.reducer