import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AssetSummaryDetailBreadCrumbs from './AssetSummaryDetailBreadCrumbs'
import AssetSummaryDetailHeaderSelector from './AssetSummaryDetailHeaderSelector'
import TabsButtons from './TabsButtons'
import AssetSummaryOverView from './AssetSummaryOverView'
import AssetDetails from './AssetDetails'
import AssetSummaryCapitalSummary from './AssetSummaryCapitalSummary'
import AssetsummaryEvents from './AssetsummaryEvents'
import AssetsummaryEventSplit from './AssetsummaryEventSplit';

function AssetSummaryDetail(props) {
  const [activeTab, setActiveTab] = useState('overview')

  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)


    useEffect(() => {
      // Switch to 'asset_details' tab after the initial render
      if (props.investment_type === false) {
        setActiveTab('asset_details');
      }
    }, []);

  const toggleTab = (name) => {
    setActiveTab(name)
  }

  return (
    <div className='asset-detail-header-section'>
      <div className='asset-detail-container'>
        <div className='page-header'>
          <AssetSummaryDetailBreadCrumbs asset_id={props.asset_id} />
          <AssetSummaryDetailHeaderSelector asset_id={props.asset_id}
                                            investment_type={props.investment_type}
                                            activeTab={activeTab}
                                            toggleTab={ toggleTab }
                                            setSelectedAssetInvestmentTypeDetail={ props.setSelectedAssetInvestmentTypeDetail }
                                            setSelectedAssetIdToViewAssetDetail={ props.setSelectedAssetIdToViewAssetDetail } />
        </div>
        <TabsButtons activeTab={activeTab} toggleTab={ toggleTab } asset_id={props.asset_id} investment_type={props.investment_type} />
        { activeTab === 'overview' && <AssetSummaryOverView asset_id={props.asset_id} /> }
        { activeTab === 'asset_details' && <AssetDetails asset_id={props.asset_id} /> }
        { activeTab === 'capital_summary' && <AssetSummaryCapitalSummary asset_id={props.asset_id} /> }
        { activeTab === 'events' && <AssetsummaryEvents asset_id={props.asset_id} /> }
        { activeTab === 'event_split' && <AssetsummaryEventSplit asset_id={props.asset_id} /> }
      </div>
    </div>
  )
}

export default AssetSummaryDetail