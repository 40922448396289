import React, { useState, useEffect, useRef, useCallback } from 'react';
import lodash from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableActionButtons from '../../../../components/table/TableActionButtons';
import Loader from '../../../../components/commonComponents/Loader';
import { getOrganizationIdOFLoggedUser, isAddAssetAllowedToLoggedUser } from '../../../../util/commonFunctions';

import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';
import { getAssetEventDetail, getEditEventInitialData, getAssetEventSplits, showEditEventModal, getEventsInitialData } from '../../../../redux/slices/assets/assetSummarySlice';
import { showConfirmationAlertModal } from '../../../../redux/slices/alertSlice';

function AssetsummaryEventSplit(props) {

    const dispatch = useDispatch()

    const user = useSelector(state => state.auth.user)
    const eventSplitsTableColumns = useSelector(state => state.assetSummary.eventSplitsTableColumns)
    const eventSplitsData = useSelector(state => state.assetSummary.eventSplitsData)
    const assetSummaryEventSplitsEntityFilter = useSelector(state => state.assetSummary.assetSummaryEventSplitsEntityFilter)
    const assetSummaryEventSplitsStartDateFilter = useSelector(state => state.assetSummary.assetSummaryEventSplitsStartDateFilter)
    const assetSummaryEventSplitsEndDateFilter = useSelector(state => state.assetSummary.assetSummaryEventSplitsEndDateFilter)

    const [assetsummaryEventsLoading, setAssetsummaryEventSplitLoading] = useState(true)

    useGetAssetsEventSplits(dispatch, props.asset_id, user, eventSplitsTableColumns, setAssetsummaryEventSplitLoading, assetSummaryEventSplitsEntityFilter, assetSummaryEventSplitsStartDateFilter, assetSummaryEventSplitsEndDateFilter)

    const getDisplayedValue = (value, name) => {
      switch (name) {
        case 'date':
        case 'entity':
        case 'type':
          return value ? value : '---';
        case 'amount':
          const formattedNumber = Math.abs(value);
          let formattedValue = value === 0 ? '---' : value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2});
          return formattedValue
        case 'share_price':
          return value ? '$' + value.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) : '---';
        default:
          return '---';
      }
    };

    const getHeaderWidth = (header) => {
      switch (header.col_key) {
        case 'date':
          return '17.5625rem';
        case 'entity':
            return '17.5625rem';
        case 'type':
          return '15.25rem';
        case 'amount':
          return '15.125rem';
        case 'share_price':
          return '10.5625rem';
        default:
          return '';
      }
    };

    return (
      assetsummaryEventsLoading ?
      <Loader height="40rem" />
      :
      <TableContainer className="events-summary-table" component={Paper}>
          <Table aria-label="simple table">
          <TableHead>
              <TableRow>
              { eventSplitsTableColumns && eventSplitsTableColumns.map((header, index) => (
                  <TableCell key={index} style={{ width: getHeaderWidth(header) }} align="left">{ header.col_name }</TableCell>
              ))}
              </TableRow>
          </TableHead>
          <TableBody>
              { eventSplitsData && eventSplitsData.map((row, index) => (
              <TableRow
                  className='table-data-row'
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                  <TableCell align="left" scope="row">{ getDisplayedValue(row.date, 'date') }</TableCell>
                  <TableCell align="left">{ getDisplayedValue(row.entity, 'entity') }</TableCell>
                  <TableCell align="left">{ getDisplayedValue(row.type, 'type') }</TableCell>
                  <TableCell align="left">{ getDisplayedValue(row.amount, 'amount') }</TableCell>
                  <TableCell align="left">{ getDisplayedValue(row.share_price, 'share_price') }</TableCell>
              </TableRow>
              ))}
          </TableBody>
          </Table>
      </TableContainer>
    );
}

export default AssetsummaryEventSplit

async function getAssetsEvents(dispatch, asset_id, user, setAssetsummaryEventSplitLoading, assetSummaryEventSplitsEntityFilter, assetSummaryEventSplitsStartDateFilter, assetSummaryEventSplitsEndDateFilter, retry = 0) {
  let params = {
    organization: getOrganizationIdOFLoggedUser(user),
    asset_id: asset_id,
    from_date: assetSummaryEventSplitsStartDateFilter,
    end_date: assetSummaryEventSplitsEndDateFilter,
    entity: assetSummaryEventSplitsEntityFilter,

  }

  const { payload } = await dispatch(getAssetEventSplits(params))

  if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
    await dispatch(getNewAccessToken());

    return getAssetsEvents(dispatch, asset_id, user, setAssetsummaryEventSplitLoading, retry + 1);
  }
  else if(payload && payload.status === 200) {
    setAssetsummaryEventSplitLoading(false)
  }
  return payload
}

function useGetAssetsEventSplits(dispatch, asset_id, user, eventSplitsTableColumns, setAssetsummaryEventSplitLoading, assetSummaryEventSplitsEntityFilter, assetSummaryEventSplitsStartDateFilter, assetSummaryEventSplitsEndDateFilter) {
  const isInitialMount = useRef(true);
  useEffect(() => {
  // This block will execute when the component re-renders due to asset_id changes
  const fetchAssetsEvents = async () => {
    const usersData = await getAssetsEvents(dispatch, asset_id, user, setAssetsummaryEventSplitLoading, assetSummaryEventSplitsEntityFilter, assetSummaryEventSplitsStartDateFilter, assetSummaryEventSplitsEndDateFilter);
  };

  setAssetsummaryEventSplitLoading(true)
  fetchAssetsEvents();
  }, [asset_id, assetSummaryEventSplitsEntityFilter, assetSummaryEventSplitsStartDateFilter, assetSummaryEventSplitsEndDateFilter]);
}