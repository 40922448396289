import { createSlice , createAsyncThunk } from '@reduxjs/toolkit'
import Http from '../../../util/Http'
import { getLoggedUserRefreshToken, getLoggedUserAccessToken } from '../../../util/commonFunctions'

export const login = createAsyncThunk('USER/login', async (  { email , password }   , { rejectWithValue }) => {
    try {
        const response = Http.post('/api/token/', { email: email, password: password })
        return response
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
})

export const resetPassword = createAsyncThunk('USER/resetPassword', async (  { password , confirm_password, token, access_token}   , { rejectWithValue }) => {
    try {
        const response = Http.put('/new_password/', { token: token, password: password, confirm_password: confirm_password }, {})
        return response
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
})

export const getNewAccessToken = createAsyncThunk('USER/getNewAccessToken', async ( values   , { rejectWithValue }) => {
    try {
        const response = Http.post('/api/token/refresh/', { refresh: getLoggedUserRefreshToken() }, {
            headers: {
                Accept: "application/json",
            },
        })
        return response
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
})

export const recoverPassword = createAsyncThunk('USER/recoverPassword', async ( values   , { rejectWithValue }) => {
    try {
        const response = Http.post('/recover_password/', values)
        return response
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
})

export const logoutUser = createAsyncThunk('USER/logoutUser', async ( values   , { rejectWithValue }) => {
    try {
        const response = await Http.post('/logout/', { refresh_token: getLoggedUserRefreshToken() }, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
        return response
    } catch (err) {
        return rejectWithValue(err)
    }
})


export const fetchLoggedUser = createAsyncThunk('USER/fetchLoggedUser', async ( values    , { rejectWithValue }) => {
    try {
        const response = await Http.get('/user-details/', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '',
        accessToken: localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '',
        refreshToken: localStorage.getItem('refresh_token') ? localStorage.getItem('refresh_token') : '',
        currentRequestId: '',
        error: "",
    },
    reducers: {
        setUserToken: (state, {payload}) => {
            if(payload){
               state.userToken = payload
            }
        },
        setUser: (state, {payload}) => {
            if(payload){
               state.user = payload
            }
        },
        clearState: (state) => {
            localStorage.clear()
            state.user = ''
            state.accessToken = ''
            state.refreshToken = ''
        }
    },
    extraReducers(builder) {
        //login response handler
        builder
          .addCase(login.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.data.success && payload.data.access_token && payload.data.refresh_token) {
                    state.user = payload.data.user
                    state.accessToken = payload.data.access_token
                    state.refreshToken = payload.data.refresh_token
                    localStorage.setItem('user', JSON.stringify(payload.data.user))
                    localStorage.setItem('access_token', JSON.stringify(payload.data.access_token))
                    localStorage.setItem('refresh_token', JSON.stringify(payload.data.refresh_token))
                }
            }
          })
          .addCase(login.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(login.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //resetPassword response handler
        .addCase(resetPassword.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
            }
        })
        .addCase(resetPassword.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(resetPassword.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
            }
        })
        //getNewAccessToken response handler
        .addCase(getNewAccessToken.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    localStorage.setItem('access_token', JSON.stringify(payload.data.access))
                    state.accessToken = payload.data.access
                }
            }
        })
        .addCase(getNewAccessToken.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(getNewAccessToken.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
            }
        })

        //recoverPassword response handler
        .addCase(recoverPassword.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
            }
        })
        .addCase(recoverPassword.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(recoverPassword.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
            }
        })

        //logoutUser response handler
        .addCase(logoutUser.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    localStorage.clear()
                    state.user = ''
                    state.accessToken = ''
                    state.refreshToken = ''
                }
            }
        })
        .addCase(logoutUser.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(logoutUser.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
            }
        })

        //fetchLoggedUser response handler
        .addCase(fetchLoggedUser.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                }
            }
        })
        .addCase(fetchLoggedUser.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(fetchLoggedUser.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
            }
        })
    }
})

export const { setUserToken, setUser, clearState } = authSlice.actions
export default authSlice.reducer