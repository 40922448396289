import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select, { components } from 'react-select';
import { createPortal } from 'react-dom';
import { getOrganizationIdOFLoggedUser } from '../../../../util/commonFunctions';

import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';
import { updateAsset } from '../../../../redux/slices/assets/assetSummarySlice';

export default function AssetSummaryTable (props) {

    const dispatch = useDispatch()

    const user = useSelector(state => state.auth.user)

    const getHeaderWidth = (header) => {
        switch (header.col_key) {
          case 'name':
            return '11.9375rem';
          case 'asset_class':
            return '8.8125rem';
          case 'sub_asset_class':
            return '8.375rem';
          case 'commitment':
            return '6.5625rem';
          case 'invested':
            return '6.125rem';
          case 'current_value':
            return '6.4375rem';
          case 'lifetime_mom':
            return '6.5625rem';
          case 'lifetime_irr':
            return '5.875rem';
          case 'status':
            return '7.8125rem';
          default:
            return '0rem';
        }
    };      
    
    const statusName = (status_code) => {
        switch (status_code) {
          case -1:
            return 'Negative';
          case 0:
            return 'Neutral';
          case 1:
            return 'Positive';
          default:
            return '';
        }
      };
      
    const statusClassName = useCallback((status_code) => {
        switch (status_code) {
          case -1:
            return 'negative';
          case 0:
            return 'neutral';
          case 1:
            return 'positive';
          default:
            return '';
        }
    }, []);
      

    const statusBadge = useCallback((status_code) => {
        return (
            <div className={"status-badge " + statusClassName(status_code)}>
                {
                    statusName(status_code)
                }
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path d="M3 4.5L6 7.5L9 4.5" stroke="#344054" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        )
    }, [])

    const getConvertedValue = useCallback((number) => {
        if(number >= 1000000000) {
            return '$' + (number  / 1000000000).toFixed(1) + " B"
        }
        if(number >= 1000000) {
            return '$' + (number  / 1000000).toFixed(1) + " M"
        }
        else if(number < 1000000) {
            return '$' + (number  / 1000).toFixed(1) + " K"
        }
    }, [])

    const getMomValue = useCallback((mom) => {
        if(mom) {
            return mom + 'x'
        }
        else {
            return ''
        }

    }, [])
    
    const getCommitmentSum = () => {
        let commitmentSum = 0;
        props.tableData.map((row, index) => {
            commitmentSum =+commitmentSum + row.commitment
        })

        return getConvertedValue(commitmentSum);
    }

    const getInvestedSum = () => {
        let investedSum = 0;
        props.tableData.map((row, index) => {
            investedSum =+investedSum + row.invested
        })

        return getConvertedValue(investedSum);
    }

    const getCurrentValueSum = () => {
        let currentValueSum = 0;
        props.tableData.map((row, index) => {
            currentValueSum =+currentValueSum + row.current_value
        })

        return getConvertedValue(currentValueSum);
    }

    const getLifetimeMoMSum = () => {
        let lifetimeMoMSum = 0;
        lifetimeMoMSum = props.summaryData.lifetime_mom
        return lifetimeMoMSum.toFixed(2) + 'x';
    }

    const getLifetimeIRRSum = () => {
        let lifetimeIRR = 0;
        lifetimeIRR = props.summaryData.lifetime_irr

        return lifetimeIRR.toFixed(2) + '%';
    }

    const options = [
        {value: -1, label: 'Negative'},
        {value: 0, label: 'Neutral'},
        {value: 1, label: 'Positive'}

    ];

    function StatusDropDown (props) {
        const customStyles = {
            indicatorSeparator: () => ({ display: 'none' })
        };


        function DownChewron () {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path d="M3 4.5L6 7.5L9 4.5" stroke="#344054" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            )
        }

        const Control = ({ children, ...props }) => {
            return (
                <components.Control {...props}>
                  {children}<DownChewron />
                </components.Control>
            )
        }  

        const [selectedValue, setSelectedValue] = useState(options.find(option => option.value === props.status_code));

        const updateAssetStatus = (status_code, retry=0) => {
            let params = {
                status: {status: status_code},
                organization: getOrganizationIdOFLoggedUser(user),
                id: props.id,
            }

            dispatch(updateAsset(params))
            .then(({payload}) => {
                if(payload && payload.status === 200) {
                }
                else if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
                    dispatch(getNewAccessToken())
                    .then((res) => {
                        if(res.payload && res.payload.data && res.payload.data.success) {
                            return updateAssetStatus(status_code, retry + 1)
                        }
                    })
                }
            })
        }

        const handleSelectChange = selectedOption => {
          setSelectedValue(selectedOption);
          updateAssetStatus(selectedOption.value)
        };

        return (
            <Select
                styles={customStyles}
                isSearchable={false}
                className={"basic-single status-selector " + statusClassName(selectedValue.value) + `${props.isLast ? ' last-status-selector' : ''}`}
                classNamePrefix="select"
                name="color"
                options={options}
                onChange={handleSelectChange}
                value={selectedValue}
                menuPlacement={"auto"} 
                components={{               
                    Control: Control,
                }}
          />
        )
    }

    const assetSummaryDetail = useCallback((asset_id, investment) => {
        props.setSelectedAssetIdToViewAssetDetail(asset_id)
        props.setSelectedAssetInvestmentTypeDetail(investment)
    }, [])

    return (
        <TableContainer className="table-container" component={Paper}>
            <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                { props.tableHeader.map((header, index) => (
                    <TableCell key={index} style={{ width: getHeaderWidth(header) }} align="left">{ header.col_name }</TableCell>
                ))}
                </TableRow>
            </TableHead>
            <TableBody>
                { props.tableData.map((row, index) => (
                <TableRow
                    className='table-data-row'
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell onClick={ () => assetSummaryDetail(row.id, row.investment) } align="left" scope="row">{row.name}</TableCell>
                    <TableCell onClick={ () => assetSummaryDetail(row.id, row.investment) } align="left">{row.asset_class}</TableCell>
                    <TableCell onClick={ () => assetSummaryDetail(row.id, row.investment) } align="left">{row.sub_asset_class}</TableCell>
                    <TableCell onClick={ () => assetSummaryDetail(row.id, row.investment) } align="left">{getConvertedValue(row.commitment)}</TableCell>
                    <TableCell onClick={ () => assetSummaryDetail(row.id, row.investment) } align="left">{getConvertedValue(row.invested)}</TableCell>
                    <TableCell onClick={ () => assetSummaryDetail(row.id, row.investment) } align="left">{getConvertedValue(row.current_value)}</TableCell>
                    <TableCell onClick={ () => assetSummaryDetail(row.id, row.investment) } align="left">{getMomValue(row.lifetime_mom)}</TableCell>
                    <TableCell onClick={ () => assetSummaryDetail(row.id, row.investment) } align="left">{row.lifetime_irr}%</TableCell>
                    <TableCell className="status" align="left"><StatusDropDown isLast={ props.tableData.length - 1 == index } status_code={row.status} id={row.id} length={props.tableData.length - 1} index={index} /></TableCell>
                </TableRow>
                ))}
                <TableRow className="total-row"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell align="left" scope="row">Total</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">{ getCommitmentSum() }</TableCell>
                    <TableCell align="left">{ getInvestedSum() }</TableCell>
                    <TableCell align="left">{ getCurrentValueSum() }</TableCell>
                    <TableCell align="left">{ getLifetimeMoMSum() }</TableCell>
                    <TableCell align="left">{ getLifetimeIRRSum() }</TableCell>
                    <TableCell align="left"></TableCell>
                </TableRow>
            </TableBody>
            </Table>
        </TableContainer>
    );
}