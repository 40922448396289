import PortfolioOverview from "../../pages/user/dashboards/portfolio/PortfolioOverview.jsx";
import AssetSummaryPage from "../../pages/user/dashboards/assets/AssetSummaryPage.jsx";
import EventsDatabase from "../../pages/user/eventsDatabase/EventsDatabase";
import EventsDataBaseIcon from "../../components/icons/EventsDataBaseIcon";
import DashboardsIcon from "../../components/icons/DashboardsIcon";
import SideBarDashboardSubMenuIcon from "../../components/icons/SideBarDashboardSubMenuIcon";

const managerRoutes = [
    {
        path: "/dashboards",
        state: "dashboards",
        parent: true,
        single: false,
        sidebarProps: {
          displayText: "Dashboards",
          icon: <DashboardsIcon />
        },
        child: [
            {
                path: "/portfolio-overview",
                element: <PortfolioOverview />,
                state: "dashboards.portfolio",
                parent: false,
                sidebarProps: {
                    displayText: "Portfolio Overview",
                    icon: <SideBarDashboardSubMenuIcon />
                },
            },
            {
                path: "/asset-summary",
                element: <AssetSummaryPage />,
                state: "dashboards.asset",
                parent: false,
                sidebarProps: {
                    displayText: "Asset Summary",
                    icon: <SideBarDashboardSubMenuIcon />
                }
            }
        ]
    },
    {
        path: "/events-database",
        element: <EventsDatabase />,
        state: "events-database",
        parent: true,
        single: true,
        sidebarProps: {
          displayText: "Events Database",
          icon: <EventsDataBaseIcon />
        }
    }
];

export default managerRoutes;