/**
 * First we will load all of this project's JavaScript dependencies like
 * axios, loadash ,React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React.
 */

import './bootstrap'

import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import './saas/app.scss';
import App from './App';

import { useSelector } from 'react-redux';

import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

// ****** Pages ***** //

import Login from './components/login/Login';
import ForgotPassword from './components/ForgotPassword';
import PublicRoute from './util/PublicRoute';
import ProtectedRoute from './util/ProtectedRoute';
import ResetPassword from './components/ResetPassword';
import Organization from './pages/admin/management/organization/Organization';
import User from './pages/admin/management/user/User';
import AccountSetting from './pages/account/AccountSetting';
import PortfolioOverview from './pages/user/dashboards/portfolio/PortfolioOverview.jsx';
import AssetSummaryPage from './pages/user/dashboards/assets/AssetSummaryPage';
import EventsDatabase from './pages/user/eventsDatabase/EventsDatabase';

import { isAdminAllowedForSpecificRoutes } from './util/commonFunctions';

// ****** Modals ***** //

import AddOrganizationModal from './components/modal/admin/organization/AddOrganizationModal';
import EditOrganizationModal from './components/modal/admin/organization/EditOrganizationModal';
import AddUserModal from './components/modal/admin/user/AddUserModal';
import EditUserModal from './components/modal/admin/user/EditUserModal';
import ConfirmationAlertModal from './components/modal/ConfirmationAlertModal';
import AlertModal from './components/modal/AlertModal';
import AddAssetModal from './components/modal/client/AddAssetModal'
import AddAssetEventModal from './components/modal/client/AddAssetEventModal';
import EditEventModal from './components/modal/client/EditEventModal';
import AddDataBaseEventModal from './components/modal/AddDataBaseEventModal';

import Http from './util/Http';

import store from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={ store }>
    <React.StrictMode>
      <BrowserRouter basename={'/'}>
        <Routes>
          <Route path='login'element={
            <PublicRoute>
              <Login />
            </PublicRoute>} 
          />
          <Route path='forgot-password'element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>}
          />
          <Route path='reset-password' element={
              <PublicRoute>
                  <ResetPassword />
              </PublicRoute>
            } />
          <Route path="/" element={<App />}>
            <Route path='organization' element={
              <ProtectedRoute allowedRoles={['admin']}>
                  <Organization />
              </ProtectedRoute>
            } />
            <Route path='user' element={
              <ProtectedRoute allowedRoles={['admin']}>
                  <User />
              </ProtectedRoute>
            } />
            <Route path='account-setting' element={
              <ProtectedRoute allowedRoles={['admin', 'user', 'manager']}>
                  <AccountSetting />
              </ProtectedRoute>
            } />
            <Route path='portfolio-overview' element={
              <ProtectedRoute allowedRoles={[isAdminAllowedForSpecificRoutes(),'user','manager']}>
                  <PortfolioOverview />
              </ProtectedRoute>
            } />
            <Route path='asset-summary' element={
              <ProtectedRoute allowedRoles={[isAdminAllowedForSpecificRoutes(),'user','manager']}>
                  <AssetSummaryPage />
              </ProtectedRoute>
            } />
            <Route path='events-database' element={
              <ProtectedRoute allowedRoles={[isAdminAllowedForSpecificRoutes(),'user','manager']}>
                  <EventsDatabase />
              </ProtectedRoute>
            } />
          </Route>
        </Routes>
      </BrowserRouter>
      {/* ********Modlas******* */}
      <AddOrganizationModal />
      <EditOrganizationModal />
      <AddUserModal />
      <EditUserModal />
      <ConfirmationAlertModal />
      <AlertModal />
      <AddAssetModal />
      <AddAssetEventModal />
      <EditEventModal />
      <AddDataBaseEventModal />
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
