import SideBarManagementsIcon from "../../components/icons/SideBarManagementsIcon";
import SideBarDashboardSubMenuIcon from "../../components/icons/SideBarDashboardSubMenuIcon";
import Organization from "../../pages/admin/management/organization/Organization";
import User from "../../pages/admin/management/user/User";

const initialAdminRoutes = [
    {
        path: "/management",
        state: "management",
        parent: true,
        single: false,
        sidebarProps: {
          displayText: "Management",
          icon: <SideBarManagementsIcon />
        },
        child: [
            {
                path: "/organization",
                element: <Organization />,
                state: "management.organization",
                parent: false,
                sidebarProps: {
                    displayText: "Organizations",
                    icon: <SideBarDashboardSubMenuIcon />
                },
            },
            {
                path: "/user",
                element: <User />,
                state: "management.user",
                parent: false,
                sidebarProps: {
                    displayText: "Users",
                    icon: <SideBarDashboardSubMenuIcon />
                }
            }
        ],
    }
];

export default initialAdminRoutes;