import React from 'react'
import { Box, ListItemButton } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import SideBarFooterUserIcon from '../icons/SideBarFooterUserIcon'

export default function SideBarFooter() {

  const location = useLocation();

  return (
    <Box
      display='flex'
      paddingTop='12px'
      alignItems='flex-start'
      gap='2.9375rem'
      alignSelf='stretch'
      borderTop='1px solid var(--gray-200, #EAECF0)'
    >
      <ListItemButton
        className="footer-nav-item"
        component={Link}
        to="account-setting"
        selected={location.pathname === '/account-setting' ? true : false}
        sx={{
          display: 'flex',
          paddingTop: '12px',
          alignItems: 'flex-start',
          gap: '2.9375rem',
          alignSelf: 'stretch',
          borderTop: '1px solid var(--gray-200, #EAECF0)',
        }}
      >
        <Box
          sx={{ cursor: 'pointer'}}
          display='flex'
          alignItems='flex-start'
          gap='0.75rem'
          alignSelf='stretch'
        >
          <SideBarFooterUserIcon />
          <span className='side-bar-footer-accout-settings'>Account Settings</span>
        </Box>
      </ListItemButton>
    </Box>
  )
}