import React from 'react';
import Select, {components} from 'react-select';
import SelectorTickIcon from '../icons/SelectorTickIcon';

function SimpleSelectInput({ field, form, placeholder, options, selectedValue, isDisable, isFormik, name, showLabel=false, handleOnChange, selectedBackgroundColor, selectedColor, showTickIconInMenu,  ...rest}) {

  const { MenuList, ValueContainer, SingleValue, Placeholder } = components;


  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? selectedColor : 'black',
      backgroundColor: state.isSelected ? selectedBackgroundColor : 'white',
      '&:hover': {
        backgroundColor: 'lightgray',
      },
    })
  };

  const handleChange = (selectedOption) => {
    if(!isFormik) {
      handleOnChange(selectedOption)
    }
    else {
      form.setFieldValue(field.name, selectedOption.value);
    }
  };

  const DropdownIndicator = () => {
    return (
      <div>
        {
          isDisable ? null :
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M5 7.5L10 12.5L15 7.5" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        }
      </div>
    );
  };

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
          { getMenuItemIconComponent(props) }
      </components.Option>
    );
  };

  const getMenuItemIconComponent = (props) => {
    if(props.showTickIconInMenu) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: '1rem' }}>
          {props.label}
          <div>
            { props.isSelected && <SelectorTickIcon /> }
          </div>
        </div>
      )
    }
    else {
      return (
        <div>
          {props.label}
        </div>
      )
    }
  }

      if (showLabel) {
        return (
          <div className="custom-select-container">
          <Select
          placeholder={ placeholder }
          className="basic-single"
          classNamePrefix="select"
          value={selectedValue}
          name={!isFormik ? name : field.name}
          options={options}
          isDisabled={isDisable}
          onChange={handleChange}
          components={{
            Option: (optionProps) => (
              <CustomOption {...optionProps} showTickIconInMenu={showTickIconInMenu}/>
            ),
            DropdownIndicator: DropdownIndicator,
          }}
          styles={customStyles}
          menuPlacement="auto"
          {...rest}
          />
            <label className={`floating-label label-floating ` + placeholder}>{placeholder}</label>

          </div>
            );
      }
      else {
          return (
              <Select
                placeholder={ placeholder }
                className="basic-single"
                classNamePrefix="select"
                value={selectedValue}
                name={!isFormik ? name : field.name}
                options={options}
                isDisabled={isDisable}
                onChange={handleChange}
                components={{
                  Option: (optionProps) => (
                    <CustomOption {...optionProps} showTickIconInMenu={showTickIconInMenu}/>
                  ),
                  DropdownIndicator: DropdownIndicator,
                }}
                styles={customStyles}
                menuPlacement="auto"
                {...rest}
              />
            );
      }



}

export default SimpleSelectInput;
