import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from "react-router-dom";
import { Box,  } from "@mui/material";
import SideBar from '../sidebar/SideBar'
import SideBarFooter from '../sidebar/SideBarFooter';
import { getLoggedUserRouteList } from '../../util/commonFunctions';

export default function AuthLayout() {

  const user = useSelector(state => state.auth.user)

  return (
    <Box
      display='flex'
      height='60rem'
      alignItems='flex-start'
      background='var(--base-white, #FFF)'
    >
      <Box
        display='flex'
        width='17.5rem'
        height='60rem'
        alignItems='flex-start'
        flexshrink={0}
        borderRight='1px solid var(--gray-200, #EAECF0)'
        background='var(--base-white, #FFF)'
      >
        < Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='flex-start'
          flex='1 0 0'
          alignSelf='stretch'
        >
          <Box
            component="nav"
            display='flex'
            paddingTop='32px'
            flexDirection='column'
            alignItems='flex-start'
            gap='1.75rem'
            alignSelf='stretch'
          >
            <SideBar routes={ getLoggedUserRouteList(user) } />
          </Box>
          <Box
            className="footer-navigation"
            component="nav"
            display='flex'
            padding='0rem 1.25rem 2rem 1.25rem'
            flexDirection='column'
            alignItems='flex-start'
            gap='1.5rem'
            alignSelf='stretch'
          >
            <SideBarFooter />
          </Box>
        </Box>
      </Box>
      <Box
        component="main"
        sx={{
          padding: '2rem 2rem 3rem 2rem',
        }}
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        gap='1.5rem'
        flex='1 0 0'
        alignSelf='stretch'
        background='#F9FAFB'
      >
        <Outlet />
      </Box>
    </Box>
  );
};