import React from 'react'
import TotalAssets from './TotalAssets'
import InvestmentAssets from './InvestmentAssets'
import YtdIRR from './YtdIRR'
import AllTimeIRR from './AllTimeIRR'

function PortfolioStatSection() {
  return (
    <div className="stat-section">
        <div className="stat-section-container">
            <div className='metric-group'>
                <TotalAssets irrType={false} />
                <InvestmentAssets irrType={false} />
                <YtdIRR irrType={true} />
                <AllTimeIRR irrType={true} />
            </div>
        </div>
    </div>
  )
}

export default PortfolioStatSection