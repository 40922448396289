import React, { useState, useRef } from 'react'

import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';


function DatePicker(props) {

  const dateRangePickerRef = useRef(null);
  const [pickerVisible, setPickerVisible] = useState(false);

  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleDateRangeChange = (event, picker) => {
    const startDate = picker.startDate.format('YYYY-MM-DD');
    const endDate = picker.endDate.format('YYYY-MM-DD');
    setDateRange({ startDate, endDate });

    if(props.getDataWithInRange) {
      props.getDataWithInRange(startDate , endDate)
    }
  };

  const initialSettings = {
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    locale: {
      customRangeLabel: 'All Time',
      format: 'MMM D, YYYY', 
    },
    ranges: {
      'Today': [new Date(), new Date()],
      'Yesterday': [
        new Date(new Date().setDate(new Date().getDate() - 1)),
        new Date(new Date().setDate(new Date().getDate() - 1))
      ],
      'This Week': [
        new Date(new Date().setDate(new Date().getDate() - new Date().getDay())),
        new Date()
      ],
      'Last Week': [
        new Date(new Date().setDate(new Date().getDate() - 7 - new Date().getDay())),
        new Date(new Date().setDate(new Date().getDate() - 1 - new Date().getDay()))
      ],
      'This Month': [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date()
      ],
      'Last Month': [
        new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
        new Date(new Date().getFullYear(), new Date().getMonth(), 0)
      ],
      'This Year': [
        new Date(new Date().getFullYear(), 0, 1),
        new Date()
      ],
      'Last Year': [
        new Date(new Date().getFullYear() - 1, 0, 1),
        new Date(new Date().getFullYear() - 1, 11, 31)
      ],
    },
    alwaysShowCalendars: true,
  };

  const getDateIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M17.5 8.33317H2.5M13.3333 1.6665V4.99984M6.66667 1.6665V4.99984M6.5 18.3332H13.5C14.9001 18.3332 15.6002 18.3332 16.135 18.0607C16.6054 17.821 16.9878 17.4386 17.2275 16.9681C17.5 16.4334 17.5 15.7333 17.5 14.3332V7.33317C17.5 5.93304 17.5 5.23297 17.2275 4.69819C16.9878 4.22779 16.6054 3.84534 16.135 3.60565C15.6002 3.33317 14.9001 3.33317 13.5 3.33317H6.5C5.09987 3.33317 4.3998 3.33317 3.86502 3.60565C3.39462 3.84534 3.01217 4.22779 2.77248 4.69819C2.5 5.23297 2.5 5.93304 2.5 7.33317V14.3332C2.5 15.7333 2.5 16.4334 2.77248 16.9681C3.01217 17.4386 3.39462 17.821 3.86502 18.0607C4.3998 18.3332 5.09987 18.3332 6.5 18.3332Z" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  }

  const toggleDateRangePicker = () => {
    const daterangepicker = document.querySelector('.daterangepicker');
    if(pickerVisible && daterangepicker) {
      daterangepicker.style.display = 'none';
    }
    else if(!pickerVisible && daterangepicker) {
      daterangepicker.style.display = 'block';
    }
    setPickerVisible(!pickerVisible)
  };

  return (
    <DateRangePicker
      onApply={handleDateRangeChange}
      initialSettings={initialSettings}
      ref={dateRangePickerRef}
      onHide={() => setPickerVisible(false)}
    >
      <button className='select-date-button' onClick={toggleDateRangePicker}>{getDateIcon()}Select dates</button>
    </DateRangePicker>
  );
}

export default DatePicker
