import { createSlice , createAsyncThunk } from '@reduxjs/toolkit'
import Http from '../../../util/Http'
import { getLoggedUserAccessToken } from '../../../util/commonFunctions'

export const getUserProfileData = createAsyncThunk('USER/getUserProfileData', async ( values    , { rejectWithValue }) => {
    try {
        const response = await Http.get('/get-profile-data/', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const updateUserProfile = createAsyncThunk('USER/updateUserProfile', async ( values   , { rejectWithValue }) => {
    try {
        const response = await Http.post('/get-profile-data/', values, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
        return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const updatePassword = createAsyncThunk('USER/updatePassword', async ( values , { rejectWithValue }) => {
    try {
        const response = await Http.put('/update-profile-password/', values, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
        return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const accountSettingSlice = createSlice({

    name: 'accountSetting',
    initialState: {
        profileUserData: [],
        user_organization_list: [],
        user_role_list: [],
        editUser: '',
        users_table_columns: '',
        addUserModalShown: false,
        editUserModalShown: false,
        tabState: 'profile',
        currentRequestId: '',
        error: "",
    },
    reducers: {
        showAddUserModal: state => {
            state.addUserModalShown = true
        },
        hideAddUserModal: state => {
            state.addUserModalShown = false
        },
        showEditUserModal: state => {
            state.editUserModalShown = true
        },
        hideEditUserModal: state => {
            state.editUserModalShown = false
            state.editUser = ''
        },
        setAccountSettingTabState: (state, { payload } ) => {
            state.tabState = payload.tabState
        }
    },
    extraReducers(builder) {
        //login response handler
        builder
          .addCase(getUserProfileData.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    state.profileUserData = payload.data
                }
            }
          })
          .addCase(getUserProfileData.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(getUserProfileData.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })

        // updateUserProfile response handler
        .addCase(updateUserProfile.fulfilled, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    state.profileUserData = payload.data
                }
            }
          })
          .addCase(updateUserProfile.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(updateUserProfile.rejected, (state, {meta, payload, error}) => {
              if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })

        //updatePassword response handler
        .addCase(updatePassword.fulfilled, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
            }
          })
          .addCase(updatePassword.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(updatePassword.rejected, (state, {meta, payload, error}) => {
              if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
    }
})

export const { showAddUserModal, hideAddUserModal, showEditUserModal, hideEditUserModal, setAccountSettingTabState } = accountSettingSlice.actions
export default accountSettingSlice.reducer