import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserBreadCrumbs from './UserBreadCrumbs';
import UserHeaderContent from './UserHeaderContent';
import UserTable from './UserTable';
import { getUserList } from '../../../../redux/slices/user/userSlice';
import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';

function User(props) {

  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)
  const users = useSelector(state => state.user.users)
  const users_table_columns = useSelector(state => state.user.users_table_columns)

  useGetUsersList(dispatch, user)

  return (
    <>
      <div className='header-section'>
        <div className='user-page-container'>
        <div className='user-page-header'>
          <UserBreadCrumbs />
          <UserHeaderContent />
        </div>
        </div>
      </div>
      { users && users_table_columns && <UserTable tableData={ users } tableHeader={ users_table_columns } /> }
    </>
  )
}

async function getUsers(dispatch, user, retry = 0) {
  const { payload } = await dispatch(getUserList())

  if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
    await dispatch(getNewAccessToken());

    // Retry the "get organization data" call with the new token
    return getUsers(dispatch, user, retry + 1);
  }

  return payload
}

function useGetUsersList(dispatch, user) {
  const isMountRef = useRef(true);
  useEffect(() => {
    if(isMountRef.current) {

      const fetchUsers = async () => {
        const usersData = await getUsers(dispatch, user);
      };
  
      fetchUsers();
      isMountRef.current = false;
    }
  }, []);
}

export default User;