import { ListItemButton, ListItemIcon, Box } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import colorConfigs from "../configs/sidebar/colorConfigs";

export default function SidebarItem ({ item, single}) {

  const location = useLocation();

  return (
    item.sidebarProps && item.path ? (
      <Box className={single ? 'single-item-without-collapse' : 'item-with-collapse'}> 
        <ListItemButton
          component={Link}
          to={item.path}
          selected={item.path === location.pathname ? true : false}
          sx={{
            fontFamily: 'Inter',
            borderRadius: '6.25rem',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '1.5rem',
            display: 'flex',
            padding: '0.5rem 0.75rem 0.5rem 1.75rem',
            alignItems: 'center',
            gap: '0.75rem',
            flex: '1 0 0',
          }}
        >
          <ListItemIcon sx={{
            color: colorConfigs.sidebar.color,
            minWidth: 0,
          }}>
            {item.sidebarProps.icon && item.sidebarProps.icon}
          </ListItemIcon>
          {item.sidebarProps.displayText}
        </ListItemButton>

      </Box>
    ) : null
  );
};