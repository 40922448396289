import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PortfolioFilters from './PortfolioFilters';

function PortfolioOverviewHeaderContent(props) {

  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)

  return (
    <div className="portfolio-overview-header-content">
      <span className="manage-portfolio-overview-text">Portfolio Overview</span>
      <PortfolioFilters />
    </div>
  )
}

export default PortfolioOverviewHeaderContent;