import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select, { components } from 'react-select';
import { getOrganizationIdOFLoggedUser } from '../../../../util/commonFunctions';

import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';
import { updateAsset } from '../../../../redux/slices/assets/assetSummarySlice';
import { getAssetSummary, getCapitalSummary } from '../../../../redux/slices/assets/assetSummarySlice';
import {round} from "lodash/math";
import Loader from '../../../../components/commonComponents/Loader';

function AssetSummaryCapitalSummary (props) {

    const dispatch = useDispatch()
  
    const user = useSelector(state => state.auth.user)
    const capitalSummaryTableColumns = useSelector(state => state.assetSummary.capitalSummaryTableColumns)
    const capitalSummaryData = useSelector(state => state.assetSummary.capitalSummaryData)

    const [assetSummaryCapitalSummaryLoading, setAssetSummaryCapitalSummaryLoading] = useState(true)

    useGetCapitalSummary(dispatch, props.asset_id, user, setAssetSummaryCapitalSummaryLoading)

    const getDisplayedValue = (value, name) => {
      switch (name) {
        case 'entity_name':
        case 'capital_returned':
        case 'outstanding_shares':
          return value ? value.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) : '---';
        case 'capital_invested':
        case 'outstanding_value':
          return value ? '$' + value.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) : '---';
        case 'outstanding_ownership':
          return value ? value.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%' : '---';
        default:
          return '---';
      }
    };    

    const getHeaderWidth = (header) => {
      switch (header.col_key) {
        case 'entity_name':
          return '19rem';
        case 'capital_invested':
          return '9.125rem';
        case 'capital_returned':
          return '9.0625rem';
        case 'outstanding_shares':
          return '10.125rem';
        case 'outstanding_ownership':
          return '11.25rem';
        case 'outstanding_value':
          return '9.6875rem';
        default:
          return '';
      }
    };

    const statusName = (status_code) => {
      switch (status_code) {
        case -1:
          return 'Negative';
        case 0:
          return 'Neutral';
        case 1:
          return 'Positive';
        default:
          return '';
      }
    };
    
    const statusClassName = useCallback((status_code) => {
      switch (status_code) {
        case -1:
          return 'negative';
        case 0:
          return 'neutral';
        case 1:
          return 'positive';
        default:
          return '';
      }
    }, []);
    

    const statusBadge = useCallback((status_code) => {
        return (
            <div className={"status-badge " + statusClassName(status_code)}>
                {
                    statusName(status_code)
                }
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path d="M3 4.5L6 7.5L9 4.5" stroke="#344054" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        )
    }, [])
    
    const getCapitalInvestedSum = () => {
      let capitalInvestedSum = 0;
      capitalSummaryData && capitalSummaryData.map((row, index) => {
          capitalInvestedSum =+capitalInvestedSum + row.capital_invested
      })

      return '$' + capitalInvestedSum.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

    const getCapitalReturnedSum = () => {
        let capitalReturnedSum = 0;
        capitalSummaryData && capitalSummaryData.map((row, index) => {
            capitalReturnedSum =+capitalReturnedSum + row.capital_returned
        })

        return '$' + capitalReturnedSum.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

    const getOutStandingSharesSum = () => {
      let outstandingSharesSum = 0;
      capitalSummaryData && capitalSummaryData.map((row, index) => {
          outstandingSharesSum =+outstandingSharesSum + row.outstanding_shares
      })

      return outstandingSharesSum.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

    const getOutStandingOwnerShipSum = () => {
        let outstandingOwnerShipSum = 0;
        capitalSummaryData && capitalSummaryData.map((row, index) => {
          outstandingOwnerShipSum =+outstandingOwnerShipSum + row.outstanding_ownership
        })

        return outstandingOwnerShipSum.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%';
    }

    const getOutStandingValuesSum = () => {
        let outStandingValuesSum = 0;
        capitalSummaryData && capitalSummaryData.map((row, index) => {
            outStandingValuesSum =+outStandingValuesSum + row.outstanding_value;
        })

        return '$' + outStandingValuesSum.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

    return (
      assetSummaryCapitalSummaryLoading ? 
      <Loader height="40rem" />
      :
      <TableContainer className="capital-asset-summary-table" component={Paper}>
          <Table aria-label="simple table">
          <TableHead>
              <TableRow>
              { capitalSummaryTableColumns && capitalSummaryTableColumns.map((header, index) => (
                  <TableCell key={index} style={{ width: getHeaderWidth(header) }} align="left">{ header.col_name }</TableCell>
              ))}
              </TableRow>
          </TableHead>
          <TableBody>
              { capitalSummaryData && capitalSummaryData.map((row, index) => (
              <TableRow
                  className='table-data-row'
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                  <TableCell align="left" scope="row">{ getDisplayedValue(row.entity_name, 'entity_name') }</TableCell>
                  <TableCell align="left">{ getDisplayedValue(row.capital_invested, 'capital_invested') }</TableCell>
                  <TableCell align="left">{ getDisplayedValue(row.capital_returned, 'capital_returned') }</TableCell>
                  <TableCell align="left">{ getDisplayedValue(row.outstanding_shares, 'outstanding_shares') }</TableCell>
                  <TableCell align="left">{ getDisplayedValue(row.outstanding_ownership, 'outstanding_ownership') }</TableCell>
                  <TableCell align="left">{ getDisplayedValue(row.outstanding_value, 'outstanding_value') }</TableCell>
              </TableRow>
              ))}
              <TableRow className="grand-total-row"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                  <TableCell align="left" scope="row">Grand Total</TableCell>
                  <TableCell align="left">{ getCapitalInvestedSum() }</TableCell>
                  <TableCell align="left">{ getCapitalReturnedSum() }</TableCell>
                  <TableCell align="left">{ getOutStandingSharesSum() }</TableCell>
                  <TableCell align="left">{ getOutStandingOwnerShipSum() }</TableCell>
                  <TableCell align="left">{ getOutStandingValuesSum() }</TableCell>
              </TableRow>
          </TableBody>
          </Table>
      </TableContainer>
    );
}

async function getCapitalAssetsSummary(dispatch, asset_id, user, setAssetSummaryCapitalSummaryLoading, retry = 0) {
  let params = {
    organization: getOrganizationIdOFLoggedUser(user),
    asset_id: asset_id,
  }
  const { payload } = await dispatch(getCapitalSummary(params))

  if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
    await dispatch(getNewAccessToken());
    return getCapitalAssetsSummary(dispatch, asset_id, user, setAssetSummaryCapitalSummaryLoading, retry + 1);
  }
  else if(payload && payload.status === 200) {
    setAssetSummaryCapitalSummaryLoading(false)
  }

  return payload
}

function useGetCapitalSummary(dispatch, asset_id, user, setAssetSummaryCapitalSummaryLoading) {
  const isInitialMount = useRef(true);
  useEffect(() => {
  // This block will execute when the component re-renders due to asset_id changes
  const fetchCapitalAssetsSummary = async () => {
    const usersData = await getCapitalAssetsSummary(dispatch, asset_id, user, setAssetSummaryCapitalSummaryLoading);
  };
  setAssetSummaryCapitalSummaryLoading(true)
  fetchCapitalAssetsSummary();
  }, [asset_id]);
}

export default AssetSummaryCapitalSummary;